import React from 'react';
import {getPageFromLocation} from "../util/path";
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {createGlobalStyle} from 'styled-components/macro';
import {useSetupIOSLogin} from "../authentication/apple_login";
import useWindowDimensions from '../util/useWindowDimensions';
import {getOnboardingPath, OnboardingPages, Pages} from '../common/pages';
import {useHousehold} from '../data/use_household';
import {getHymnFromUrl} from '../shared';

export const Root = () => {
  const windowDimensions = useWindowDimensions();
  const {household} = useHousehold();
  const location = useLocation()
  useSetupIOSLogin();
  return (
    <>
      <GlobalStyles $windowHeight={windowDimensions.height} $windowWidth={windowDimensions.width}/>
      {
        household || getPageFromLocation(location) === Pages.Onboarding || getHymnFromUrl(location.search)
          ? <Outlet/>
          : <Navigate to={getOnboardingPath(OnboardingPages.Demo)}/>
      }
    </>
  );
}

const GlobalStyles = createGlobalStyle<{$windowHeight: number, $windowWidth: number}>`
  * {
    --view-height: ${props => props.$windowHeight}px;
    --view-width: ${props => props.$windowWidth}px;
  }
`;
