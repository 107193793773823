import React, {useCallback, useEffect, useState} from 'react';
import "./slide_menus.css"
import {Kebab} from "./kebab";
import {PlayerPageOptions} from "./player_page_options"
import {isDesktop} from "react-device-detect";
import {Hymn} from "./sequencer";
import {SongInfo} from "./song_info";


interface Props {
  show: boolean
  hymn: Hymn;
  close: () => void;
  displayPdf: () => void;
  showModal: () => void;
}

export const SlideMenu = ({show, hymn, close, displayPdf, showModal}: Props) => {
  enum Menu {Kebab,Info, Options}
  const [display, setDisplay] = useState(Menu.Kebab)
  const [landscape, setLandscape] = useState(false)


  useEffect(() => {
    if(window.innerHeight < window.innerWidth) {
      setLandscape(true)
    } else {
      setLandscape(false)
    }
    // dependencies are actually valid
    // eslint-disable-next-line
  }, [window.innerHeight, window.innerWidth])


  const handlePdf = useCallback(() => {
    close()
    setTimeout(() => displayPdf(), 200)
  }, [close, displayPdf]);

  const renderKebab = useCallback(() => {
    return (
      <div className="slideMenu" style={show && display === Menu.Kebab ?
        {top: landscape && !isDesktop ? "50%" : "60%", transition: "top 250ms"}
        :
        {top: "100%"}}>
        <div className="wrapper">
          <Kebab displaySongInfo={() => setDisplay(Menu.Info)}
                 displaySongOptions={() => setDisplay(Menu.Options)}
                 displayPdf={() => handlePdf()}
                 displayModal={showModal}
                 close={close}
                  />
        </div>
      </div>
    )
  }, [close, display, handlePdf, landscape, Menu, show, showModal]);

  const renderOptions = useCallback(() => {
    return  (
      <div className="slideMenu" style={show && display === Menu.Options ?
        {top: landscape && !isDesktop ? "30%" : "35%", transition: "top 250ms"}
        :
        {top: "100%"}}>
        <div className="wrapper">
          <PlayerPageOptions back={() => setDisplay(Menu.Kebab)} hymn={hymn}/>
        </div>
      </div>
    )
  }, [Menu, display, hymn, landscape, show]);

  function renderSongInfo() {
    return  (
      <div className="slideMenu" style={show && display === Menu.Info ?
        {top: landscape && !isDesktop ? "30%" : "45%", transition: "top 250ms"}
        :
        {top: "100%"}}>
        <div className="wrapper">
          <SongInfo back={() => setDisplay(Menu.Kebab)} hymn={hymn}/>
        </div>
      </div>
    )
  }


  const slideMenuBackground = document.querySelector("#slideMenuBackground")
  slideMenuBackground?.addEventListener("click", () => {
    close()
    setDisplay(Menu.Kebab)
  })

  return (
    <div>
    <div
      id="slideMenuBackground"
      style={show ? {zIndex: 190} : {zIndex: -20}}
      className={show ? 'slideMenuModalOverlay' : ''}
    />
      {renderKebab()}
      {renderSongInfo()}
      {renderOptions()}
    </div>


  )
}
