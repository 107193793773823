import "../settings_page.css"
import "./shared.css"
import {ReactComponent as CheckMarkIcon} from "../../assets/check-mark-icon.svg";
import {useLocalStorage} from "../../data/use_local_storage";
import {getSongIntroName, LocalStorageKey, SongIntroduction} from "../../data/client_local_storage";
import {useCallback} from 'react';

type Props = {
  back: () => void;
}

export const SongIntroductionSettingPage = (props: Props) => {

  const [songIntroduction, setSongIntroduction] = useLocalStorage(LocalStorageKey.SongIntroduction);

  const onSetSongIntroduction = useCallback((value: SongIntroduction | undefined) => {
    setSongIntroduction(value);
    props.back();
  }, [setSongIntroduction, props]);

  function renderSongIntroductionOption(optionValue?: SongIntroduction): JSX.Element {
    return (
      <div key={optionValue ?? "None"} className="setting verseCountSetting" onClick={
        () => onSetSongIntroduction(optionValue)
      }>
        <div key="label" className="verses">
          {getSongIntroName(optionValue)}
        </div>
        <div key="value" className="checkMarkCell" hidden={songIntroduction !== optionValue}>
          <div className="checkMarkIcon">
            <CheckMarkIcon/>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="settingsContent">
      <div className="settingsBox">
        <div className="settings verseCountSettings">
          {renderSongIntroductionOption(undefined)}
          {
            Object.values(SongIntroduction).map((value) => renderSongIntroductionOption(value))
          }
        </div>
      </div>
      <div className="explanation">
        Song Introduction determines whether part or all of the song is played prior to
        the first verse.  Begins playing at the measure nearest to the specified
        duration.
      </div>
    </div>
  )
}
