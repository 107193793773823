export function ensure(value: any, message?: string): asserts value {
  if (!value) {
    throw new Error(message ?? `value not truthy`);
  }
}

export function ensureExists<T>(value: T | undefined | null, msg?: string): T {
  if (typeof (value) === 'undefined' || value === null) {
    throw new Error(msg ?? 'Expected value to be defined and not null');
  }
  return value;
}

export function ensureUnreachable(value: never, message?: string): never {
  throw new Error(`Unreachable code ${message}`);
}

export function userVisibleSongNumber(number: number): string {
  const decimal = number % 1;
  const suffix = (decimal ? String.fromCharCode(Math.round(decimal * 100) + 64) : '').toLowerCase();
  return Math.floor(number).toString() + suffix;
}

export function isValidEmail(email?: string): boolean {
  if (!email) {
    return false;
  }
  return Boolean(
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ));
}

export function objectsEqual(lhs: any, rhs: any) {
  return JSON.stringify(lhs) === JSON.stringify(rhs)
}

export function capitalize(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
