import React, {useCallback, useContext, useEffect} from 'react';
import {isInsideAppleAppStoreApp} from '../../util/billing';
import {getPlatform, Platform} from '../../util/platform';
import {AppContext, AppContextInterface} from './app_context';
import {OnboardingPages} from '../../common/pages';
import {ReactComponent as SmallBackArrowIcon} from '../../assets/arrow-previous-left-icon.svg';
import styled from 'styled-components/macro';

const maxHeight = '1000px';
const maxWidth = '500px';
const minHeight = '450px';

interface Props {
  onboardingPage: OnboardingPages | undefined;
  mainContent: React.ReactElement;
  footerContent: React.ReactElement;
  suppressBackButton?: boolean;
  onEnterKeypress?: () => void;
  containsInputElement?: boolean;
  containsMultipleButtons?: boolean;
  isSmallBackButton?: boolean;
  onBack?: () => void;
  alignTop?: boolean;
  onClose?: () => void;
  embed?: boolean;
}

export const Frame = ({
  onboardingPage,
  mainContent,
  footerContent,
  suppressBackButton,
  onEnterKeypress,
  containsInputElement,
  containsMultipleButtons,
  isSmallBackButton,
  onBack,
  alignTop,
  onClose,
  embed,
}: Props) => {
  const appContext = useContext(AppContext);

  const onKeyDown = useCallback((evt: KeyboardEvent) => {
    if (evt.code === 'Enter') {
      onEnterKeypress?.();
    }
  }, [onEnterKeypress]);

  const locationMatchesCurrentPage = useCallback(() => {
    const {hash} = window.location;
    const hashPage = hash && hash.slice(1);
    const hasNoOnboardingPage = !onboardingPage || onboardingPage === OnboardingPages.Demo;
    const allOnboardingPages = Object.values(OnboardingPages) as string[];
    if (hashPage === onboardingPage) {
      return true;
    }
    return hasNoOnboardingPage && (!hashPage || !allOnboardingPages.includes(hashPage));
  }, [onboardingPage]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    }
  }, [onKeyDown]);

  useEffect(() => {
    if (locationMatchesCurrentPage()) {
      appContext?.setSuppressBackButton(Boolean(suppressBackButton));
      // when passing a function to a setter, it assumes you want to invoke it to store the result -
      // like when you are initializing a state variable (see https://stackoverflow.com/a/68019979/998490)
      appContext?.setOnBack(() => onBack);
      appContext?.setOnClose(() => onClose);
    }
  }, [appContext, locationMatchesCurrentPage, onboardingPage, onBack, onClose, suppressBackButton]);

  const onBackCallback = useCallback(() => {
    navigateBack(appContext, onBack);
  }, [appContext, onBack]);

  // The Android mobile keyboard contains a "Go" button intended to submit a <form>.
  // Including a <form> in the page causes the browser to reload server content on back & submit.
  // Thus, we squeeze the page vertically for Android so that the Continue button is always visible.
  // If we similarly squeeze the page on iOS, the page gets scrolled off the top by the keyboard.  :/
  const squeezePageHeight = containsInputElement && getPlatform() === Platform.Mobile;

  let height: string;
  if (squeezePageHeight) {
    height = '0';
  } else if (isInsideAppleAppStoreApp()) {
    height = '100vh'
  } else {
    height = 'var(--view-height, 100vh)';
  }

  const innerWrapper = (
    <InnerWrapper
      $squeezePageHeight={squeezePageHeight}
      $containsMultipleButtons={containsMultipleButtons}
      $embed={embed}
    >
      {isSmallBackButton && (
        <BackButton key='back' onClick={onBackCallback}>
          <SmallBackArrow/>
        </BackButton>
      )}
      <MainWrapper $alignTop={alignTop}>
        {mainContent}
      </MainWrapper>
      <FooterWrapper $squeezePageHeight={squeezePageHeight} $embed={embed}>
        {footerContent}
      </FooterWrapper>
    </InnerWrapper>
  );

  if (embed) {
    return innerWrapper;
  }

  return (
    <OuterWrapper $squeezePageHeight={squeezePageHeight} style={{height: height}}>
      {innerWrapper}
      {!embed &&
        <LandscapeMessage>
          Please rotate your phone to portrait
        </LandscapeMessage>
      }
    </OuterWrapper>
  );
}

export function navigateBack(appContext: AppContextInterface | null, onBack?: () => void) {
  appContext?.setPageAnimation('moveToRightFromLeft');
  if (onBack) {
    onBack();
  } else {
    window.history.back();
  }
}

export const BackButton = styled.button`
  z-index: 1;                      // TODO(hewitt): should not be necessary - wasn't when in frame.ts
  position: absolute;
  top: 15px;
  left: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const OuterWrapper = styled.div<{$squeezePageHeight: boolean | undefined}>`
  display: grid;
  place-items: ${props => props.$squeezePageHeight ? 'start' : 'center'};
  width: 100vw;
  height: 100%;
  background-color: var(--color-background);
`;

const InnerWrapper = styled.div<{$squeezePageHeight?: boolean, $containsMultipleButtons?: boolean, $embed?: boolean}>`
  display: grid;
  position: relative;
  grid-template-rows: minmax(${props => props.$embed ? '0' : (props.$containsMultipleButtons ? '180px' : '250px')}, auto) max-content;
  font-size: min(max(4.5vw, 1em), 1.5em);

  // Account for mobile nav bar height (iOS Safari fails to access the CSS variable, so short circuit)
  height: min(${maxHeight}, ${props => props.$squeezePageHeight || props.$embed
      ? '100%'
      : (isInsideAppleAppStoreApp() ? '100vh' : 'var(--view-height, 100vh)')
  });
  width: min(${maxWidth}, 100%);

  // hide content in landscape
  @media (max-height: ${minHeight}) and (orientation:landscape) and (min-aspect-ratio: 13/9) {
    display: ${props => props.$embed ? 'revert' : 'none'};
  }

  // TODO(hewitt): Prevent screen rotation during onboarding
  //--screen-height-not-rotated: max(100vw, var(--view-height, 100vh));
  //--screen-width-not-rotated: min(100vw, var(--view-height, 100vh));
  //height: var(--screen-height-not-rotated);
  //width: var(--screen-width-not-rotated);
  //@media (orientation: landscape) {
  //  transform-origin: calc(100vw / 2) calc(100vh / 2);
  //  // transform-origin: calc(100vh / 2) calc(100vw / 2);
  //  transform: rotate(-90deg);
  //}
  // tried placing the following in the page header
  // <meta http-equiv="ScreenOrientation" content="autoRotate:disabled">
`;

const MainWrapper = styled.div<{$alignTop?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => props.$alignTop ? 'start' : 'center'};
`;

const LandscapeMessage = styled.div`
  display: none;
  font-size: 1.3em;
  @media (max-height: ${minHeight}) and (orientation:landscape) and (min-aspect-ratio: 13/9) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

const FooterWrapper = styled.div<{$squeezePageHeight?: boolean, $embed?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: ${props => props.$squeezePageHeight ? '-50px' : '0'};
  padding-top: ${props => props.$embed ? '10px' : '25px'};
  padding-bottom: ${props => props.$embed ? '20px' : '40px'}; 
  background-color: var(--color-background);
`;

const SmallBackArrow = styled(SmallBackArrowIcon)`
  color: var(--color-text-light);
  height: 28px;
  padding-left: 10px;
  padding-top: 8px;
`;
