import './footer.css';
import {ReactComponent as LibraryBooks} from '../assets/library-books.svg';
import {ReactComponent as SettingsIcon} from "../assets/settings-outlined.svg";
import {ReactComponent as HelpIcon} from "../assets/question-circle-outlined.svg";
import {ReactComponent as CalendarIcon} from "../assets/calendar-outlined.svg";
import {ReactComponent as OutlinedHeartIcon} from '../assets/heart-outline.svg';
import {isDesktop} from 'react-device-detect';
import {getUrlForPage, getPageFromLocation} from "../util/path";
import {isWKWebView} from "../authentication/apple_login";
import {Pages} from '../common/pages';
import styled from 'styled-components/macro';
import {useInAppAnnouncementActive} from './onboarding/in_app_onboarding_announcement';
import {Link, useLocation} from 'react-router-dom';

export const Footer = () => {
  const location = useLocation();
  const page = getPageFromLocation(location);
  const showRedDot = useInAppAnnouncementActive();

  function getIconClassName(forPage: Pages) {
    let sizeClassName: string;
    switch (forPage) {
      case Pages.ThisWeek:
        sizeClassName = 'thisWeekIcon';
        break;
      case Pages.Library:
        sizeClassName = 'libraryIcon' + (forPage === page ? ' selectedLibraryIcon' : ' unselectedLibraryIcon');
        break;
      case Pages.Settings:
        sizeClassName = 'settingsIcon';
        break;
      case Pages.Favorites:
        sizeClassName = 'favoritesIcon';
        break;
      case Pages.Help:
        sizeClassName = 'helpIcon';
        break;
      default:
        sizeClassName = '';
        break;
    }
    return sizeClassName + ' footerIcon ' + (forPage === page ? 'selectedIcon' : 'unselectedIcon');
  }

  function getLabelClassName(forPage: Pages) {
    return forPage === page ? 'selectedLabel iconLabel' : 'unselectedLabel iconLabel';
  }

  // TODO(hewitt): Should be using <NavLink> to style currently active tab
  return (
    <div key='footer' className='footer' style={isWKWebView() || isDesktop? {paddingBottom: 0} : {paddingBottom: 20}}>
      <Link key='this-week' className="iconDiv" to={getUrlForPage(Pages.ThisWeek)}>
        <CalendarIcon className={getIconClassName(Pages.ThisWeek)} />
        <div className={getLabelClassName(Pages.ThisWeek)}>This Week</div>
      </Link>
      <Link key='library' className="iconDiv" to={getUrlForPage(Pages.Library)}>
        <LibraryBooks className={getIconClassName(Pages.Library)}/>
        <div className={getLabelClassName(Pages.Library)}>Library</div>
      </Link>
      <Link key='favorites' className="iconDiv" to={getUrlForPage(Pages.Favorites)}>
        <OutlinedHeartIcon className={getIconClassName(Pages.Favorites)}/>
        <div className={getLabelClassName(Pages.Favorites)}>Favorites</div>
      </Link>
      <Link key='settings' className="iconDiv" to={getUrlForPage(Pages.Settings)}>
        <SettingsIcon className={getIconClassName(Pages.Settings)}/>
        <div className={getLabelClassName(Pages.Settings)}>Settings</div>
      </Link>
      <Link key='help' className="iconDiv" to={getUrlForPage(Pages.Help)}>
        <HelpIcon className={getIconClassName(Pages.Help)}/>
        <div className={getLabelClassName(Pages.Help)}>Help</div>
        {showRedDot && <RedDot/>}
      </Link>
    </div>
  )
};

const RedDot = styled.div`
  position: absolute;
  background-color: red;
  width: 13px;
  height: 13px;
  border-radius: 13px;
  top: 5%;
  right: 30%;
`;
