import {useLocalStorage} from '../../data/use_local_storage';
import {LocalStorageKey, localStorageRemove} from '../../data/client_local_storage';
import React, {useCallback} from 'react';
import {BoldMessage, isAdminInvite, isHouseholdInvite, Message, navigateToOnboardingPage, subscribing} from './common';
import {Frame} from './frame';
import {Button} from './button';
import {alert} from '../../util/confirm';
import {synchronizeChurchWithServer, useChurch} from '../../data/use_church';
import {OnboardingPages, Pages} from '../../common/pages';
import {HouseholdStatus} from '../../common/model';
import {withSearchParams} from '../../util/use_location';
import {getUrlForPage} from '../../util/path';
import {useNavigate} from 'react-router-dom';

export const HouseholdJoin = () => {
  const [householdEmail] = useLocalStorage(LocalStorageKey.OnboardingHouseholdEmail);
  const [household] = useLocalStorage(LocalStorageKey.Household);
  const [onboardingChurch] = useLocalStorage(LocalStorageKey.OnboardingChurch);
  const {setChurch} = useChurch();
  const navigate = useNavigate();

  const onJoinHousehold = useCallback(async () => {
    if (!household) {
      await alert({confirmation: <span>An error occurred joining household</span>});
      navigateToOnboardingPage(navigate, OnboardingPages.HouseholdEmail);
      return;
    }
    if (isAdminInvite()) {
      navigate(withSearchParams(getUrlForPage(Pages.AdminInvite)));
      return;
    }
    if (isHouseholdInvite()) {
      navigate(withSearchParams(getUrlForPage(Pages.HouseholdInvite)));
      return;
    }
    if (onboardingChurch) {
      setChurch(onboardingChurch);
    } else {
      await synchronizeChurchWithServer({force: true});
    }
    let page: OnboardingPages;
    if (subscribing()) {
      if (household.status === HouseholdStatus.Subscribed) {
        page = OnboardingPages.SubscribeThankYou;
      } else {
        page = OnboardingPages.SubscribePayment;
      }
    } else {
      page = OnboardingPages.ShareFriendsFamily;
    }
    navigateToOnboardingPage(navigate, page);
  }, [household, onboardingChurch, setChurch, navigate]);
  const onEditEmail = useCallback(() => {
    localStorageRemove(LocalStorageKey.Household);
    navigateToOnboardingPage(navigate, OnboardingPages.HouseholdEmail);
  }, [navigate]);
  return (
    <Frame
      onboardingPage={OnboardingPages.HouseholdJoin}
      mainContent={(
        <Message $widthInChars={26}>
          A household already exists with email <BoldMessage>{householdEmail}</BoldMessage>.<br/><br/>
          {subscribing() ? 'Subscribe this household' : 'Would you like to join it'}?
        </Message>
      )}
      footerContent={(
        <>
          <Button onClick={onJoinHousehold}>YES</Button>
          <Button onClick={onEditEmail}>NO</Button>
        </>
      )}
      onEnterKeypress={onJoinHousehold}
    />
  );
};
