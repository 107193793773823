import * as server_api from '../../../src/common/server_api';
import styled from 'styled-components/macro';
import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import '../../shared.css'
import '../settings_page.css'
import './shared.css'
import {HouseholdStatus, AppConfig} from '../../common/model';
import {
  AppleSubscriptionInfo,
  clearStoredHouseholdInfo,
  isInsideAppleAppStoreApp,
  isInsideGooglePlayApp,
  isInsideMobileApp,
  manageAppleSubscription,
  requestAppleSubscriptionInfo,
  useSetupIOSSubscriptionInfoCallback
} from '../../util/billing';
import {Header} from '../header';
import {ChooseChurchPage} from '../choose_church_page';
import {ReactComponent as ForwardArrowIcon} from '../../assets/arrow-next-right-icon.svg';
import {SignUpButton} from '../../util/sign_up_button';
import {confirm} from '../../util/confirm'
import {SettingsTransitionWrapper} from '../../shared';
import {useChurch} from '../../data/use_church';
import {useHousehold} from '../../data/use_household';
import {LocalStorageKey, localStorageRemove} from '../../data/client_local_storage';
import {useAppConfig} from '../../data/app_config';
import {capitalize} from '../../common/util';
import {HouseholdSchedule} from './household_schedule';

enum Display {Household, ChooseChurch, HouseholdSchedule}

type Props = {
  visible: boolean;
  householdCreation?: boolean;
  confirmHousehold?: () => Promise<void>;
  setLastName?: (name: string) => void;
  setEmail?: (value: string) => void;
}

export const HouseholdSettingsPage = (props: Props) => {
  const {household, setHousehold} = useHousehold();
  const {church} = useChurch();
  const churchName = church?.name ?? "No Church";
  const [display, setDisplay] = useState(Display.Household);

  const householdIsEditable = useAppConfig(AppConfig.AllowEditableHousehold);

  const onSettingChanged = useCallback(() => {
    setDisplay(Display.Household);
  }, []);

  const wrapWithTransition = useCallback((
    title: string,
    component: ReactElement,
    page: Display,
    {suppressHeader}: {suppressHeader?: boolean} = {}
  ) => (
    <SettingsTransitionWrapper $left={(display === page) ? "0" : "100%"}>
      {
        !suppressHeader &&
        <Header title={title} onBack={() => setDisplay(Display.Household)} householdCreation={props.householdCreation} />
      }
      {component}
    </SettingsTransitionWrapper>
  ), [display, props.householdCreation]);

  const renderChooseChurch = useCallback(() => {
    return wrapWithTransition(
      'Choose Church',
      <ChooseChurchPage
        visible={display === Display.ChooseChurch}
        onSettingChanged={onSettingChanged}
        onBack={() => setDisplay(Display.Household)}
      />,
      Display.ChooseChurch,
      {suppressHeader: true},
    );
  }, [display, onSettingChanged, wrapWithTransition]);

  const renderHouseholdSchedule = useCallback(() => {
    return wrapWithTransition(
      'Schedule',
      <HouseholdSchedule
        visible={display === Display.HouseholdSchedule}
        onBack={() => setDisplay(Display.Household)}
      />,
      Display.HouseholdSchedule,
      {suppressHeader: true},
    );
  }, [display, wrapWithTransition]);

  const onDeleteHousehold = useCallback(async () => {
    if (await confirm({confirmation: <span>Are you sure you wish to<br/>delete your household?</span>})) {
      clearStoredHouseholdInfo();
      const householdToken = household?.token;
      if (!householdToken) {
        return;
      }
      // clear all household data on server
      await server_api.clearHousehold({token: householdToken});
      window.location.href = '/settings';
    }
  }, [household]);

  const showDeleteButton = isInsideMobileApp() && household?.householdEmail;

  const SubscribeOrConfirmButton = useCallback(() => {
    if (props.householdCreation && props.confirmHousehold) {
      return <ConfirmButton onClick={props.confirmHousehold}>Confirm</ConfirmButton>
    }

    if (household?.status !== HouseholdStatus.Subscribed) {
      return <SubscribeButton/>
    }

    return null
  },[
    household,
    props.confirmHousehold,
    props.householdCreation,
  ]);

  const onShowChooseChurch = useCallback(() => {
    setDisplay(Display.ChooseChurch)
  }, []);

  const {schedule} = household?.surveyResponses ?? {};
  const onShowSchedule = useCallback(() => {
    setDisplay(Display.HouseholdSchedule)
  }, []);

  function deleteHouseholdLocalStorageEntries() {
    localStorageRemove(LocalStorageKey.Household);
    localStorageRemove(LocalStorageKey.Church);
    localStorageRemove(LocalStorageKey.WeeklySongList);
  }

  const onHouseholdSignOut = useCallback(async () => {
    if (await confirm({confirmation: <span>Are you sure you wish to<br/>Sign Out?</span>})) {
      deleteHouseholdLocalStorageEntries();
      window.location.href = '/settings';
    }
  }, []);

  const renderHousehold = useCallback(() => (
    <div className="settingsBox accountSettings">
      <div className="settingBoxHeader">Settings</div>
      <table className="settings">
        <tbody>
        <tr className="setting arrow">
          <td className="settingName">Last Name</td>
          <td className="currentValue">
            {
              householdIsEditable ?
                <InvisibleInput
                  type='text'
                  autoFocus={!!props.householdCreation}
                  defaultValue={household?.familyLastName}
                  onChange={(event) => {
                    if (props.setLastName) {
                      props.setLastName(event.target.value);
                    }
                  }}
                  onBlur={(event) => {
                    if (props.householdCreation) {
                      return
                    }
                    let newHousehold = {...household};
                    newHousehold.familyLastName = event.target.value;
                    setHousehold(newHousehold);
                  }}
                />
                :
                household?.familyLastName
            }
          </td>
          <td className='settingAction'></td>
        </tr>
        <tr className="setting arrow">
          <td className="settingName">Email</td>
          <td className="currentValue">
            {
              householdIsEditable ?
                <InvisibleInput
                  type='email'
                  defaultValue={household?.householdEmail}
                  onChange={(event) => {
                    if (props.setEmail) {
                      props.setEmail(event.target.value);
                    }
                  }}
                  onBlur={(event) => {
                    if (props.householdCreation) {
                      return
                    }
                    let newHousehold = {...household};
                    newHousehold.householdEmail = event.target.value;
                    setHousehold(newHousehold);
                  }}
                />
                :
                household?.householdEmail
            }
          </td>
          <td className='settingAction'></td>
        </tr>
        <tr onClick={onShowChooseChurch} className="setting arrow">
          <td className="settingName">Church</td>
          <td className="currentValueEllipse">{churchName}</td>
          <td>
            <ForwardArrow/>
          </td>
        </tr>
        { household?.surveyResponses && (
          <tr onClick={onShowSchedule} className="setting arrow">
            <td className="settingName">Schedule</td>
            <td className="currentValueEllipse">{schedule ? schedule.map(entry => capitalize(entry)).join('/') : ''}</td>
            <td>
              <ForwardArrow/>
            </td>
          </tr>
        )}
        <tr onClick={onHouseholdSignOut} className="setting arrow">
          <RedSettingName className="settingName">Sign Out</RedSettingName>
          <td className="currentValue"></td>
          <td/>
        </tr>
        <tr onClick={onDeleteHousehold} className={'setting arrow' + (showDeleteButton ? '' : ' hidden')}>
          <RedSettingName className="settingName">Delete</RedSettingName>
          <td className="currentValue"></td>
          <td/>
        </tr>
        </tbody>
      </table>
      <SubscribeOrConfirmButton/>
      <ManageSubscriptionDiv>
        <ManageSubscription/>
      </ManageSubscriptionDiv>
    </div>
  ), [
    churchName,
    household,
    householdIsEditable,
    onDeleteHousehold,
    onHouseholdSignOut,
    onShowChooseChurch,
    onShowSchedule,
    props,
    schedule,
    setHousehold,
    showDeleteButton,
    SubscribeOrConfirmButton,
  ]);

  if (!props.visible) {
    return null;
  }

  return (
    <div className="settingsContent">
      {renderHousehold()}
      {renderChooseChurch()}
      {renderHouseholdSchedule()}
    </div>
  )
}

const SubscribeButton = () => (
  <SubscribeButtonWrapper>
    <SignUpButton subscribeButton={true}/>
  </SubscribeButtonWrapper>
);

const ManageSubscription = () => {
  const [hasAppleSubscription, setHasAppleSubscription] = useState<boolean>(false);
  const {household} = useHousehold();
  const householdStatus = household?.status;

  // determine if iOS thinks we have a subscription (noop on other platforms)
  useSetupIOSSubscriptionInfoCallback(
    (subscriptionInfo: AppleSubscriptionInfo) => setHasAppleSubscription(Boolean(subscriptionInfo.expirationDate))
  );
  useEffect(() => { requestAppleSubscriptionInfo() }, []);

  const manageStripeSubscription = useCallback(() => {
    window.location.href = `https://billing.stripe.com/p/login/aEU8zfeTE1LU5l6aEE?prefilled_email=${household?.householdEmail}`;
  }, [household]);

  if (householdStatus !== HouseholdStatus.Subscribed) {
    return null;
  }

  // TODO(hewitt): how to bring up Google Play subscription management UI?
  if (isInsideAppleAppStoreApp() && hasAppleSubscription) {
    return <ManageSubscriptionButton onClick={manageAppleSubscription}>Manage Subscription</ManageSubscriptionButton>
  }

  if (isInsideGooglePlayApp()) {
    return (
      <div>
        Manage your subscription in Google Play on your device.
      </div>
    )
  }

  return (
    <div>
      <ManageSubscriptionButton onClick={manageStripeSubscription}>Manage Subscription</ManageSubscriptionButton>
    </div>
  );
}

const ManageSubscriptionButton = styled.button`
  color: white;
  background-color: black;
  font-size: 1em;
  padding: 5px 15px;
  border-radius: 30px;
  margin-top: 10px;
  cursor: pointer;
`

const SubscribeButtonWrapper = styled.div`
  margin-top: 20px;
`

const ConfirmButton = styled.button`
    background-color: #d6d6d6;
    border: none;
    height: 40px;
    width: 90px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    margin-top: 36px;
    box-shadow: 1px 1px 3px #888888;
`

const ManageSubscriptionDiv = styled.div`
    width: 100%;
    position: fixed;
    bottom: 6%;
    text-align: center;
`

const InvisibleInput = styled.input`
    width: 100%;
    height: 23px;
    text-align: right;
    border: none;
    outline: none;
    color: var(--color-text-light);
    background-color: var(--color-background);
    overflow: hidden;
    font-size: 16px;
    text-overflow: ellipsis;
`

const RedSettingName = styled.td`
    color: red;
`

export const ForwardArrow = styled(ForwardArrowIcon)`
  padding-left: 5px;
  width: 12px;
  fill: var(--color-text-light);
`;
