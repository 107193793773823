import {Hymn} from "../sequencer";
import {Location} from 'history';
import {ensureExists} from "../common/util";
import {Pages} from '../common/pages';

export function generateFileName(hymn: Hymn) {
  let titleAllCaps = ensureExists(hymn.title).toUpperCase();
  const match = hymn.number.toString().match(/^(?<wholeNumber>\d+)(\.(?<decimal>\d+))?/);
  let number: string;
  if (match && match.groups) {
    const {wholeNumber, decimal} = match.groups;
    number = wholeNumber.padStart(3, '0') + (decimal ? `.${decimal.padEnd(2, '0')}` : '');
  } else {
    console.log(`Error parsing hymn number ${hymn.number}`);
    number = hymn.number.toString();
  }

  if(titleAllCaps.match(/^PSALM \d+$/)) {
    return number + ' - ' + hymn.title;
  }
  else {
    if(hymn.psalm) {
      return number + ' - ' + hymn.title + ' - ' + hymn.psalm;
    }
    else {
      return number + ' - ' + hymn.title;
    }
  }
}

export function generatePathFor(base: string, hymnalName: string, fileName: string) {
  // Cantus Christi hymnals were renamed to include parenthesis (e.g. "Cantus Christi (2002)")
  // Some churches still have the old links (e.g. The King's Congregation)
  if (hymnalName.includes('Cantus Christi') && !hymnalName.includes('(')) {
    hymnalName = hymnalName.replace(/(\d+)/, '($1)')
  }
  return `${base}/${encodeMusicURI(hymnalName)}/${encodeMusicURI(fileName)}`;
}

export function encodeMusicURI(uri: string) {
  return encodeURIComponent(uri)
    .replace(/!/g, '%21');
}

export function getPageFromLocation(location: Location | undefined): Pages | undefined {
  if (!location) {
    return;
  }
  const pagePart = location.pathname.match(/\/(?<page>[^/]+)/)?.groups?.page;
  return pagePart && Object.values(Pages).includes(pagePart as Pages) ? pagePart as Pages : undefined;
}

export function getUrlForPage(page: Pages) {
  return '/' + page;
}

export function generateHymnUrl(hymn: Hymn, page?: Pages): string {
  const hymnalName = hymn.hymnal.replace(/ /g, '_');
  const hymnTitle = hymn.title.replace(/ /g, '_');
  const hymnNumber = hymn.number;
  let psalmName;

  if (hymn.psalm) {
    psalmName = hymn.psalm.replace(/ /g, '_');
  } else {
    psalmName = '';
  }

  const url: string =
    getUrlForPage(page ?? Pages.Library) + '?' +
    'hymnal=' + hymnalName +
    '&title=' + hymnTitle +
    '&number=' + hymnNumber.toString() +
    '&psalm=' + psalmName +
    (hymn.basePath ? '&basePath=' + hymn.basePath : '') +
    (hymn.issue ? '&issue=' + hymn.issue : '') +
    (hymn.extensions ? '&extensions=' + JSON.stringify(hymn.extensions) : '');

  return (url);
}