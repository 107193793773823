import {User} from "../common/model";
import {LocalStorageKey, localStorageSet} from "../data/client_local_storage";
import {favorites} from "../data/favorites";
import {useLocalStorage} from "../data/use_local_storage";
import {useCallback} from "react";

export function useAuthenticationHelper() {
  const setCredentials = useLocalStorage(LocalStorageKey.Credentials)[1];
  const [, setUser] = useLocalStorage(LocalStorageKey.User);
  const setUserAndCredentials = useCallback((user: User, credentials: string, userToken: string) => {
    setUser(user);
    setCredentials(credentials);
    localStorageSet(LocalStorageKey.UserToken, userToken);
    void favorites.reconcileWithServer();
  }, [setCredentials, setUser]);
  return {setUserAndCredentials};
}