import React, {useCallback} from 'react';
import {Frame} from './frame';
import {ButtonContent, Message, navigateToOnboardingPage} from './common';
import {Button, LeftAlignedButton} from './button';
import styled from 'styled-components/macro';
import {useLocalStorage} from '../../data/use_local_storage';
import {LocalStorageKey} from '../../data/client_local_storage';
import {ConfidenceLevel} from '../../common/model';
import {OnboardingPages} from '../../common/pages';
import {useNavigate} from 'react-router-dom';

interface Option {
  prompt: string;
  confidenceLevel: ConfidenceLevel;
}

export const SurveyConfidenceLevel = () => {
  const navigate = useNavigate();

  const onContinue = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.SurveyExpectedUsage);
  }, [navigate]);

  const options: Option[] = [
    {prompt: 'I can\'t sing', confidenceLevel: ConfidenceLevel.Unable},
    {prompt: 'I try to sing sometimes', confidenceLevel: ConfidenceLevel.Sometimes},
    {prompt: 'I sing at church regularly', confidenceLevel: ConfidenceLevel.Regularly},
    {prompt: 'I sing in the choir/praise team', confidenceLevel: ConfidenceLevel.Choir},
    {prompt: 'I lead the choir/praise team', confidenceLevel: ConfidenceLevel.Leader},
  ];

  const [selectedConfidenceLevel, setSelectedConfidenceLevel] = useLocalStorage(LocalStorageKey.OnboardingConfidenceLevel);

  const onChooseOption = useCallback((confidenceLevel: ConfidenceLevel) => {
    return () => {
      setSelectedConfidenceLevel(confidenceLevel);
    };
  }, [setSelectedConfidenceLevel]);

  return (
    <Frame
      onboardingPage={OnboardingPages.SurveyConfidenceLevel}
      mainContent={(
        <>
          <Message $widthInChars={16}>How confident do you feel singing?</Message>
          <ButtonContent>
            {options.map(option => (
              <LeftAlignedButton
                key={option.confidenceLevel}
                onClick={onChooseOption(option.confidenceLevel)}
                notPrimary={true}
                selected={option.confidenceLevel === selectedConfidenceLevel}
              >
                <OptionWrapper>
                  {option.prompt}
                </OptionWrapper>
              </LeftAlignedButton>
            ))}
          </ButtonContent>
        </>
      )}
      footerContent={(
        <Button onClick={onContinue} disabled={!selectedConfidenceLevel}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};

const OptionWrapper = styled.div`
  padding: 10px 25px;
`;
