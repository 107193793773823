import React, {useCallback, useEffect} from 'react';
import {Message, navigateToOnboardingPage, SubscribeContextQueryString, Title} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {useHousehold} from '../../data/use_household';
import styled from 'styled-components/macro';
import {
  AppleProductId,
  getAnnualSubscriptionPrice,
  getMonthlySubscriptionPrice,
  GoogleProductId,
  isInsideAppleAppStoreApp,
  isInsideGooglePlayApp, isInsideMobileApp,
  StripePaymentLinkAnnual,
  StripePaymentLinkAnnualTest,
  StripePaymentLinkMonthly
} from '../../util/billing';
import * as server_api from '../../common/server_api';
import {LocalStorageKey, localStorageSet} from '../../data/client_local_storage';
import {HouseholdStatus, PaymentProcessor} from '../../common/model';
import {OnboardingPages} from '../../common/pages';
import {useNavigate} from 'react-router-dom';

export const SubscribePayment = () => {
  const {household} = useHousehold();
  const isTestEnv = window.location.hostname.startsWith('test') || window.location.hostname.startsWith('localhost');
  const navigate = useNavigate();

  useEffect(() => {
    if (isInsideAppleAppStoreApp()) {
      // @ts-ignore navigate to app upon successful subscription
      globalThis.receiveSubscriptionInfo = (appleSubscriptionInfo) => {
        const subscription = {
          paymentProcessor: PaymentProcessor.Apple,
          appAccountToken: appleSubscriptionInfo.appAccountToken,
          subscriptionExpirationTimestamp: parseAppleDateAsTimestamp(appleSubscriptionInfo.expirationDate),
          productId: appleSubscriptionInfo.productId,
        };
        localStorageSet(LocalStorageKey.ClientSubscription, subscription);
        navigate('/');
      };
    }
  }, [navigate]);

  const onAnnually = useCallback(() => {
    if (!household?.token) {
      return;
    }
    if (isInsideAppleAppStoreApp()) {
      onSubscribeApple(household.token, AppleProductId.Annual);
    } else if (isInsideGooglePlayApp()) {
      void onSubscribeGoogle(household.token, GoogleProductId.Annual);
    } else {
      window.location.href = (isTestEnv ? StripePaymentLinkAnnualTest : StripePaymentLinkAnnual);
    }
  }, [household?.token, isTestEnv]);

  const onMonthly = useCallback(() => {
    if (!household?.token) {
      return;
    }
    if (isInsideAppleAppStoreApp()) {
      onSubscribeApple(household.token, AppleProductId.Monthly);
    } else if (isInsideGooglePlayApp()) {
      void onSubscribeGoogle(household.token, GoogleProductId.Monthly);
    } else {
      window.location.href = StripePaymentLinkMonthly;
    }
  }, [household?.token]);

  // We don't allow the user to go back from the payment page because the previous pages have stale info.
  // So, we give the option of closing instead if they don't want to subscribe.
  const onClose = useCallback(() => {
    navigate('/');
  }, [navigate]);

  useEffect(() => {
    if (!household) {
      // TODO(hewitt): redirect back to subscribe page at end of household creation
      navigateToOnboardingPage(navigate, OnboardingPages.HouseholdFind, SubscribeContextQueryString);
      return;
    } else if (household.status === HouseholdStatus.Subscribed) {
      if (isInsideMobileApp()) {
        navigate('/');
      } else {
        navigateToOnboardingPage(navigate, OnboardingPages.Download);
      }
    }
  }, [household, navigate]);

  return (
    <Frame
      onboardingPage={OnboardingPages.SubscribePayment}
      mainContent={(
        <>
          <Free>30 Days Free</Free>
          <Message $widthInChars={24}>
            Would you like to subscribe annually or monthly?
          </Message>
        </>
      )}
      // TODO(hewitt): use correct pricing based on context
      footerContent={(
        <>
          <Button onClick={onAnnually}>{`ANNUALLY $${getAnnualSubscriptionPrice()}`}/year</Button>
          <Button onClick={onMonthly}>{`MONTHLY $${getMonthlySubscriptionPrice()}`}/year</Button>
        </>
      )}
      onEnterKeypress={onAnnually}
      suppressBackButton={true}
      onClose={onClose}
    />
  );
};

const Free = styled(Title)`
  font-size: 2.1em;
  padding-bottom: 2em;
`;

function onSubscribeApple(householdToken: string, productId: AppleProductId) {
  // @ts-ignore
  window.webkit?.messageHandlers?.subscribe?.postMessage({productId, householdToken});
}

async function onSubscribeGoogle(householdToken: string, productId: GoogleProductId) {
  try {
    console.log(`Creating digitalGoodsService`);
    // @ts-ignore
    const digitalGoodsService = await window.getDigitalGoodsService("https://play.google.com/billing");
    console.log(`digitalGoodsService created: ${digitalGoodsService}`);
    const paymentMethods = [{
      supportedMethods: "https://play.google.com/billing",
      data: {sku: productId}
    }];
    // TODO(hewitt): is the current household subscribed via Google Play -> how to connect the household ID?
    //               can we squeeze the household token into the payment details such that it sticks?
    // Even though the payment details are required, the Play Billing will ignore those values and use the
    // values set when creating the SKU in the Play Console, so they can be filled with bogus values:
    const paymentDetails = {
      id: householdToken,
      total: {label: `Total`, amount: {currency: `USD`, value: `0`}}
    };
    const request = new PaymentRequest(paymentMethods, paymentDetails);
    const paymentResponse = await request.show();
    console.log(`Google Play payment response: ${JSON.stringify(paymentResponse)}`);

    try {
      // Assume subscription starts today, server will update expiration date by calling Google Play
      const subscriptionExpirationTimestamp = productId === GoogleProductId.Monthly
        ? new Date(new Date().setFullYear(new Date().getFullYear(), new Date().getMonth() + 1)).getTime()
        : new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getTime();
      await server_api.postGooglePlayPayment({
        householdToken,
        // @ts-ignore paymentResponse does not necessarily include details.itemId, so we supply it since we know it
        paymentResponse: {...paymentResponse, details: {itemId: productId, ...paymentResponse.details}},
        subscriptionExpirationTimestamp
      });
      console.log('Successfully recorded server subscription');
    } catch (e: any) {
      console.error(`ERROR: Server post of google play payment failed ${e.message}`);
    }
    await paymentResponse.complete('success');

    // // Call backend to validate and acknowledge the purchase.
    // if (await acknowledgePurchaseOnBackend(purchaseToken, sku)) {
    //   // Optional: consume the purchase, allowing the user to purchase
    //   // the same item again.
    //   service.consume(purchaseToken);
    //
    //   // Optional: tell the PaymentRequest API the validation was
    //   // successful. The user-agent may show a "payment successful"
    //   // message to the user.
    //   const paymentComplete = await paymentResponse.complete('success');
    // } else {
    //   // Optional: tell the PaymentRequest API the validation failed.
    //   // The user agent may show a message to the user.
    //   const paymentComplete = await paymentResponse.complete('fail');
    // }
    // TODO(hewitt): Store ClientSubscriptionLocalStorageEntryName like with Apple above?
    // TODO(hewitt): Update household subscription timestamp
    window.location.href = '/';
  } catch (e: any) {
    // TODO(hewitt): Handle purchase failure
    console.log(`ERROR: Purchase failed:\n${e.message}\n${e.stack}`)
  }
}

function parseAppleDateAsTimestamp(dateString: string): number | undefined {
  // date string has format "2024-09-27 18:24:42 +0000"
  if (!dateString) {
    return undefined;
  }
  const match = dateString.match(/^(?<year>\d+)-(?<month>\d+)-(?<day>\d+) .*/);
  if (!match || !match.groups) {
    console.log(`ERROR: could not parse Apple date "${dateString}"`);
    return undefined;
  }
  const {year, month, day} = match.groups;
  return new Date(Number(year), Number(month) - 1, Number(day)).getTime();
}
