import '../shared.css';
import './hymns_list.css';
import React, {Fragment, ReactElement, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
  churchHymnalIconPath,
  favoritesHymnalIconPath,
  getHymnFromUrl,
  InnerPageContent,
  OuterPageContent
} from "../shared";
import {customMusicDir, hymnalsDir, musicManifestFilename} from "../common/paths";
import {Header} from "./header";
import {isCustomSong, PlayerPage} from './player_page';
import {ReactComponent as CloseCircleIcon} from "../assets/close-circle.svg";
import {ReactComponent as PlayIcon} from "../assets/play.svg";
import {ReactComponent as SearchIcon} from "../assets/search.svg";
import {Footer} from "./footer";
import {Hymn} from "../sequencer";
import {favorites} from "../data/favorites";
import {useLocalStorage} from "../data/use_local_storage";
import {LocalStorageKey} from "../data/client_local_storage";
import * as server_api from "../common/server_api"
import {generateHymnUrl, getPageFromLocation, getUrlForPage} from "../util/path";
import {ensureExists, userVisibleSongNumber} from "../common/util";
import {NavigateFunction, useLocation, useNavigate} from 'react-router-dom';
import {
  HouseholdStatus,
  Hymnal,
  HymnalManifest,
  HymnalsManifest,
  HymnIssue,
  isAdminForCurrentChurch,
  OrganizationType,
  SongFeature,
  SongListEntry,
  SongListType,
} from "../common/model";
import {ReactComponent as VocalsIcon} from "../assets/vocals.svg";
import styled from 'styled-components/macro';
import {useUserAttributes} from "../data/use_user_attributes";
import {synchronizeChurchWithServer, useChurch} from "../data/use_church";
import {OptInButton, SignUpButton} from '../util/sign_up_button';
import {isInsideMobileApp} from '../util/billing';
import {synchronizeHouseholdWithServer, useHousehold} from '../data/use_household';
import {maybeUpgradeApp} from '../util/app_upgrade';
import {Pages} from '../common/pages';

export const LibraryPageTitle = 'Library';
const MaxSearchResultsPerHymnal = 50;
const PrimaryChurchHymnalId = Number.MAX_SAFE_INTEGER;
const PrimaryChurchHymnalSlug = `PRIMARY`

const customMusicManifestPath = `/${customMusicDir}/${musicManifestFilename}`;
const hymnalsManifest = `/${hymnalsDir}/${musicManifestFilename}`;

const churchIdsThatSortByNumber = [
  11 // Christ Covenant Church (Skagit County WA)
];

export interface HymnalWithHymns extends Hymnal {
  hymns: Hymn[];
  message?: JSX.Element;
}

export interface Props {
  title: string;
  weeklySongGroups?: HymnalWithHymns[];
  expandForThisWeek: boolean;
  message?: JSX.Element;
  showFavorites?: boolean;
  isDemo?: boolean;
}

export function getHymnFromSongListEntry(song: SongListEntry): Hymn {
  return {...song, title: song.title ?? 'Untitled'};
}

function getHymnsFromHymnalManifest(
  hymnalName: string, hymnalManifest: HymnalManifest, {filter, basePath}: {
    filter?: (number: string) => boolean;
    basePath?: string;
  } = {}
): Hymn[] {
  return Object.entries(hymnalManifest)
    // hide songs without suffixes that match songs with suffixes (e.g. hide 17 if 17.01 and 17.02 exist)
    // leave song in if the .01 is marked "alt." (see CC2020 597 & 597a), in which case the count is 2
    .filter(([number]) => !filter || filter(number))
    .map(([number, data]) => ({...data, number: Number(number), hymnal: hymnalName, ...(basePath && {basePath})}))
    .sort((lhs, rhs) => lhs.number - rhs.number)
}

export const HymnsList = (props: Props) => {
  const [expandedHymnals, setExpandedHymnals] = useState<HymnalWithHymns[]>([]);
  const [searchString, setSearchString] = useState('');
  const [hymnals, setHymnals] = useState<Hymnal[]>([]);
  const [publishers, setPublishers] = useState<Record<string, string>>({});
  const [downloadedHymnals, setDownloadedHymnals] = useState<HymnalWithHymns[]>([]);
  const [allHymnals, setAllHymnals] = useState<HymnalWithHymns[]>([]);
  const [searchHasFocus, setSearchHasFocus] = useState(false);
  const [favoriteHymns, setFavoriteHymns] = useState<Hymn[]>([]);
  const [credentials] = useLocalStorage(LocalStorageKey.Credentials);
  const [currentHymnalName, setCurrentHymnalName] = useLocalStorage(LocalStorageKey.CurrentHymnalName);
  const {isInternalUser} = useUserAttributes();
  const {church} = useChurch();
  const [churchHymnal, setChurchHymnal] = useState<HymnalWithHymns | undefined>(church && {
    name: church.name,
    id: PrimaryChurchHymnalId,
    hymns: [],
    isChurchSpecific: true,
    slug: PrimaryChurchHymnalSlug,
  });
  const {household} = useHousehold();
  const householdStatus = household?.status;
  const [songsForWeek] = useLocalStorage(LocalStorageKey.WeeklySongList);
  const navigate = useNavigate();
  const favoritesHymnal = useMemo<HymnalWithHymns>(() =>
    createFavoritesHymnal({credentials, householdStatus, hymns: favoriteHymns, navigate}),
    [credentials, householdStatus, favoriteHymns, navigate]
  );

  // state var for whether to display player or not, sort of like a boolean but is a Hymn obj
  // initialize to undefined
  const [playlist, setPlaylist] = useState<Hymn[] | undefined>(undefined);
  const hymnalManifest = (hymnalName: string) => `/${hymnalsDir}/${hymnalName}/${musicManifestFilename}`;
  const location = useLocation();
  const page = getPageFromLocation(location);
  const {search} = location;
  const currentHymnal = allHymnals.find(hymnal => hymnal.name === currentHymnalName);

  useEffect(() => {
    setAllHymnals([
      ...(churchHymnal ? [churchHymnal] : []),
      ...(credentials ? [favoritesHymnal] : []),
      ...downloadedHymnals
    ]);
  }, [church, churchHymnal, credentials, favoritesHymnal, downloadedHymnals]);

  useEffect(() => {
    if (props.expandForThisWeek && props.weeklySongGroups?.length) {
      // For This Week, expand both Sundays
      setExpandedHymnals(props.weeklySongGroups);
    }
  }, [props.expandForThisWeek, props.weeklySongGroups]);
  const hymnalIcon = (hymnalName: string, id: number) => {
    if (id === PrimaryChurchHymnalId) {
      return churchHymnalIconPath;
    }
    if (hymnalName === favoritesHymnal.name) {
      return favoritesHymnalIconPath;
    }
    return `/${hymnalsDir}/${hymnalName}/icon.png`;
  }
  const searchInputRef = useRef<HTMLInputElement>(null);

  const singleHymnal = !props.weeklySongGroups && !props.showFavorites && currentHymnalName;
  const title = singleHymnal ? currentHymnalName : props.title;
  const hidePaidContent = householdStatus !== HouseholdStatus.Subscribed && !isInternalUser;
  const isSingleStaticList = props.weeklySongGroups?.length === 1 && props.weeklySongGroups[0].name === '';
  const maxSearchResultsPerHymnal = !singleHymnal && !props.showFavorites && !isSingleStaticList
    ? MaxSearchResultsPerHymnal : Number.MAX_SAFE_INTEGER;

  const songClickIsEnabled = useCallback((hymnal?: Hymnal) =>
    Boolean(!hidePaidContent || (props.weeklySongGroups && !searchString) || hymnal?.isChurchSpecific), [
      hidePaidContent, props.weeklySongGroups, searchString
  ]);

  // download hymnals manifest
  useEffect(() => {
    fetch(hymnalsManifest)
      .then((response) => response.json())
      .then((hymnalsManifest: HymnalsManifest) => {
        (async () => {
          const serverHymnals: Hymnal[] = Object.entries(hymnalsManifest)
            .filter(([_name, data]) => isInternalUser || !data.isInternal)
            .map(([name, data]) => ({
              name,
              ...data,
            }));
          setHymnals(serverHymnals);
          setPublishers(Object.entries(hymnalsManifest).reduce(
            (acc, [hymnalName, entry]) => {
              acc[hymnalName] = entry.publisher;
              return acc;
            }, {} as Record<string, string>)
          );
        })();
      })
  }, [isInternalUser]);

  const countSongNumbers = useCallback((hymnalManifest: HymnalManifest) => {
    return Object.keys(hymnalManifest)
      .map(key => key.match(/^\d+/)?.[0])
      .filter(key => key !== undefined)
      .reduce((prev, cur) => {
        // @ts-ignore (TS not yet smart enough to apply the filter)
        prev[cur] = (prev[cur] || 0) + 1;
        return prev;
      }, {} as {[number: string]: number});
  }, []);

  useEffect(() => {
    void (async () => {
      if (!church || downloadedHymnals.length === 0 || (props.weeklySongGroups && !isSingleStaticList)) {
        return;
      }
      const sourceHymnal = downloadedHymnals.find(hymnal => hymnal.id === church.primaryHymnalId);
      const staticHymns = songsForWeek?.find(list => list.type === SongListType.StaticList)?.songs.map(
        song => getHymnFromSongListEntry(song)
      );
      const customMusicManifestResponse = await fetch(customMusicManifestPath);
      const customMusicManifest = await customMusicManifestResponse.json() as HymnalsManifest;
      const churchManifestKey = Object.keys(customMusicManifest).find(key => key.startsWith(`${church.id} - `));
      let customSongs: Hymn[] = [];
      try {
        const customSongsResponse = churchManifestKey &&
          await fetch(`/${customMusicDir}/${churchManifestKey}/${musicManifestFilename}`);
        const customChurchMusicManifest = customSongsResponse && await customSongsResponse.json() as HymnalManifest;
        customSongs = customChurchMusicManifest ?
          getHymnsFromHymnalManifest(churchManifestKey, customChurchMusicManifest, {basePath: `/${customMusicDir}`}) : [];
      } catch (error: any) {
        console.log(
          `Failed to load custom song manifest for "${churchManifestKey}"; skipping custom songs: ${error.message}`
        );
      }
      const skipPunctuation = (title: string) => title[0].match('[a-zA-Z]') ? title : title.slice(1);
      const hymns = [
        ...customSongs,
        ...(sourceHymnal ? sourceHymnal.hymns : []),
        ...(staticHymns ? staticHymns : [])
      ]
        .filter(hymn => hymn.title)
        .sort((lhs, rhs) =>
            churchIdsThatSortByNumber.includes(church.id)
              ? lhs.number - rhs.number
              : skipPunctuation(lhs.title).localeCompare(skipPunctuation(rhs.title))
        );
      setChurchHymnal({
        name: church.name,
        id: PrimaryChurchHymnalId,
        hymns,
        isChurchSpecific: true,
        slug: PrimaryChurchHymnalSlug,
      });
    })();
  }, [church, downloadedHymnals, isSingleStaticList, props.weeklySongGroups, household, songsForWeek]);

  // download each hymnal
  useEffect(() => {
    if (hymnals.length === 0) {
      return;
    }
    Promise.all(hymnals.map(hymnal => fetch(hymnalManifest(hymnal.name))))
      .then((responses) => Promise.all(responses.map(response => response.json())))
      .then((hymnalManifests: HymnalManifest[]) => {
        const populatedHymnals: HymnalWithHymns[] = hymnalManifests.map((hymnalManifest, i) => {
          const hasSuffix = (number: string) => Boolean(number.match(/\d+\.\d+/));
          const songNumbers = countSongNumbers(hymnalManifest);
          return {
            ...hymnals[i],
            hymns: getHymnsFromHymnalManifest(hymnals[i].name, hymnalManifest,
              // hide songs without suffixes that match songs with suffixes (e.g. hide 17 if 17.01 and 17.02 exist)
              // leave song in if the .01 is marked "alt." (see CC2020 597 & 597a), in which case the count is 2
              {filter: (number: string) => hasSuffix(number) || songNumbers[number] <= 2})
          };
        });
        for (const hymnal of populatedHymnals) {
          favorites.registerHymnal(hymnal);
        }
        setDownloadedHymnals(populatedHymnals);
      })
  }, [countSongNumbers, hymnals]);

  // refresh favorites
  useEffect(() => {
    if (!credentials) {
      return;
    }
    setFavoriteHymns(favorites.get());
    const token = favorites.registerCallback(() => {
      setFavoriteHymns(favorites.get());
    });
    void favorites.reconcileWithServer();
    return () => favorites.unregisterCallback(token);
  }, [credentials]);

  function serviceWorkerListener(event: any) {
    if (event.data === 'ReloadPage') {
      window.location.reload();
    }
  }

  useEffect(() => {
    navigator.serviceWorker?.addEventListener('message', serviceWorkerListener);
    return () => navigator.serviceWorker?.removeEventListener('message', serviceWorkerListener);
  }, [])

  useEffect(() => {
    void maybeUpgradeApp();
  }, []);

  useEffect(() => {
    // ignore bogus cached hymnal name (e.g. if a hymnal is removed)
    if (currentHymnalName && allHymnals.length > 0 && !allHymnals.some(hymnal => hymnal.name === currentHymnalName)) {
      setCurrentHymnalName(undefined);
    }
  }, [allHymnals, currentHymnalName, setCurrentHymnalName]);

  const songList: Hymn[] = []

  function createSongList() {
    let hymnals: HymnalWithHymns[];
    if (props.weeklySongGroups) {
      // For This Week page, only show all hymnals if the customer is subscribed
      hymnals = searchString ? allHymnals : props.weeklySongGroups;
    } else if (props.showFavorites) {
      hymnals = [favoritesHymnal];
    } else if (currentHymnal) {
      hymnals = [currentHymnal];
    } else {
      hymnals = (expandedHymnals.length === 0 && searchString) ? allHymnals : expandedHymnals;
    }
    for (const hymnal of hymnals) {
      for (const hymn of getVisibleHymns(hymnal)) {
        if(hymn.issue && (hymn.issue === HymnIssue.Music || hymn.issue === HymnIssue.Missing)) {
          continue;
        }
        songList.push(hymn)
      }
    }
  }

  const searchRegEx = useMemo(() => {
    // if search string starts with nonAlphaNumeric character(s) chop them off
    // also remove all apostrophes (removed from filenames during search as well)
    const searchTrimmed = searchString
      .replace(/^[^a-z\d'’]+/gi, '')
      .replace(/['’]/g, '')
    const spaceRegEx = `[a-z0-9]*[^a-z0-9']+`
    const searchTerms = searchTrimmed.replaceAll(/[^a-z\d'’]+/gi, spaceRegEx)
    return new RegExp(searchTerms, 'gi')
  }, [searchString]);

  function generateSongDisplayName(hymn: Hymn) {
    const displayName = hymn.title;

    const match = displayName.match(searchRegEx)

    const startIndex = displayName.search(searchRegEx)
    const endIndex = startIndex + (match && match.length ? match[0].length : 0)

    const beforeBold = displayName.slice(0, startIndex)
    const bold = displayName.slice(startIndex, endIndex)
    const afterBold = displayName.slice(endIndex, displayName.length)

    return <SongNameWrapper>
      <div>
        {beforeBold}
        <b>{bold}</b>
        {afterBold}
      </div>
      <VocalsIconWrapper $visible={hymn.features?.includes(SongFeature.Vocals)}>
        <VocalsIcon className="vocalsIcon"/>
      </VocalsIconWrapper>
    </SongNameWrapper>
  }

  function getVisibleHymns(hymnal: HymnalWithHymns) {
    return hymnal.hymns
      .filter((hymn) => {
        const number = userVisibleSongNumber(hymn.number);
        const psalmSuffix = hymn.psalm && !ensureExists(hymn.title).toLowerCase().includes('psalm') ? ' - ' + hymn.psalm : '';
        const hymnString = number + ' - ' + hymn.title.replace(/['’]/g, '') + psalmSuffix;
        return hymnString.search(searchRegEx) !== -1
      })
  }

  function hymnalNameShouldBeWrapped(hymnalName: string) {
    // only wrap if the time or location is specified
    return hymnalName.includes('@');
  }

  function renderHymnalHeadingAndHymns({
    headingIndex,
    headingCount,
    firstChildIndex,
    hymnal,
    displayHymnalTitle,
    lastHymnalNameIsWrapped,
  }: {
    headingIndex: number;
    headingCount: number;
    firstChildIndex: number;
    hymnal: HymnalWithHymns;
    displayHymnalTitle: boolean;
    lastHymnalNameIsWrapped: boolean;
  }): (ReactElement | null)[] {
    const visibleHymns = getVisibleHymns(hymnal);
    const nthChild = firstChildIndex + 1;

    function scrollIntoView() {
      // Because headings (This Week, Last Week) are sticky, if we scroll them into view, the scroll calculation
      // will be based on where the header is stuck rather than where it currently resides in the scrolled list,
      // causing it not to scroll into the correct location.
      // For example, say the header is stuck to the bottom, but its current position is actually off the bottom
      // of the screen.  When we scroll it into view, the scroll calculation will determine the distance from its
      // stuck location to the desired scroll location and scroll by that amount.  This will not scroll far enough.
      // Instead, it should have calculated based on the distance from where it currently resides in the scrolled
      // list, which is off the bottom of the screen.
      // So, we scroll based on the first child of the header, which is not sticky, and therefore always scrolls to the
      // correct location on the screen, causing the header to also be positioned correctly.
      // Both Safari & Chrome exhibit the same behavior - not sure about other browsers, but don't really care.
      const firstHymnId = (nthChild + 1).toString() + '-hymn';
      document.getElementById(firstHymnId)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }

    const disabledStyle = !songClickIsEnabled(hymnal) ? {color: 'var(--color-text-light)'} : undefined;

    if (hymnal.message) {
      return [
        <HymnalHeading
          key={hymnal.name + '-heading'}
          hidden={Boolean(props.showFavorites || singleHymnal)}
          onClick={scrollIntoView}
        >
          <HymnalNameRow colSpan={100}>
            <HymnalNameGroup>
              <HymnalName style={disabledStyle}>
                {hymnal.name}
              </HymnalName>
            </HymnalNameGroup>
          </HymnalNameRow>
        </HymnalHeading>,
        <tr key={hymnal.name + '-message'}>
          <HymnalMessage colSpan={100}>{hymnal.message}</HymnalMessage>
        </tr>
      ];
    }

    function wrapHymnalName(hymnalName: string): ReactElement {
      let hymnalNameParts: ReactElement[];
      if (!hymnalNameShouldBeWrapped(hymnalName)) {
        hymnalNameParts = [
          <HymnalName key={hymnalName} style={disabledStyle}>
            {hymnalName}
          </HymnalName>
        ];
      } else {
        const parts = hymnalName.split('-');
        hymnalNameParts = [
          <HymnalName key={hymnalName} style={disabledStyle}>
            {parts[0].trim()}
          </HymnalName>,
          <HymnalNameSecondLine key='hymnalNameSecondLine'>{parts[1].trim()}</HymnalNameSecondLine>
        ];
      }
      return (
        <HymnalNameRow colSpan={100}>
          <HymnalNameGroup>
            {hymnalNameParts}
          </HymnalNameGroup>
        </HymnalNameRow>
      );
    }

    const tableRows = Array.from(visibleHymns.slice(0, maxSearchResultsPerHymnal + 1).entries())
      .map(([index, hymn]) => {
        const hymnUrl = generateHymnUrl(hymn, props.isDemo ? Pages.Library : page);

        const openSong = () => handleClick(hymnal, hymn);
        const thisRowNthChild = nthChild + index + 1;
        const hymnId = thisRowNthChild.toString() + '-hymn';

        if (index === maxSearchResultsPerHymnal) {
          return <HymnRow key='ellipsis'><td /><EllipsisCell colSpan={100}>...</EllipsisCell></HymnRow>;
        }

        function navigateToSong() {
          if (!songClickIsEnabled(hymnal)) {
            return;
          }
          navigate(hymnUrl);
        }

        const numberCell = (
          <td key='songNumber' className='number songLink' onClick={navigateToSong}>
            {isCustomSong(hymn) ? '' : userVisibleSongNumber(hymn.number)}
          </td>
        )

        const titleCell = (
          <td key='songName' className='songName songLink' colSpan={hymn.psalm ? 1 : 2} onClick={navigateToSong}>
            {generateSongDisplayName(hymn)}
          </td>
        )

        const psalmCell = (
          <td key='psalm' className='psalm songLink'  onClick={navigateToSong}>
            {hymn.psalm && hymn.psalm.substring(0, 2) + '.' + hymn.psalm.substring(5)}
          </td>
        )

        const key = hymnal.name + ' ' + hymn.number + ' ' + hymn.title + ' ' + thisRowNthChild.toString();
        const disabledClassname = (hymn.issue && hymn.issue !== HymnIssue.Text) || !songClickIsEnabled(hymnal)
          ? 'disabledSong' : '';
        const content = [
          numberCell,
          titleCell,
          hymn.psalm ? psalmCell : null
        ];

        // It is very expensive to utilize a custom styled component for every row in the hymnal when only the
        // first hymn after the heading is used for scrolling.  Thus, we only use a non-custom styled component (below)
        // for all the rest of the hymns.
        if (index === 0) {
          return (
            <ScrollableHymnRow
              key={key}
              id={hymnId}
              onClick={() => openSong()}
              className={disabledClassname}
              $headingIndex={headingIndex}
              $nthChild={thisRowNthChild}
            >
              {content}
            </ScrollableHymnRow>
          );
        }
        return (
          <HymnRow key={key} id={hymnId} onClick={() => openSong()} className={disabledClassname}>
            {content}
          </HymnRow>
        )
      });

    let hymnalHeading = null;
    if (displayHymnalTitle) {
      hymnalHeading = headingCount > 5 || church?.type === OrganizationType.School ? (
        <HymnalHeading
          key={hymnal.name + '-heading'}
          onClick={scrollIntoView}
        >
          {wrapHymnalName(hymnal.name)}
        </HymnalHeading>
      ) : (
        <HymnalHeadingWithAccordion
          key={hymnal.name + '-heading'}
          $headingIndex={headingIndex}
          $headingCount={headingCount}
          $nthChild={nthChild}
          $lastHymnalNameIsWrapped={lastHymnalNameIsWrapped}
          onClick={scrollIntoView}
        >
          {wrapHymnalName(hymnal.name)}
        </HymnalHeadingWithAccordion>
      );
    }

    return [hymnalHeading, ...tableRows];
  }

  const cancelSearch = useCallback(() => {
    setSearchString('');
  }, []);

  // todo: correct type for keyboard event
  const onSearchKeyUp = useCallback((e: any /*KeyboardEvent*/) => {
    if (e.key === 'Escape') {
      cancelSearch();
      searchInputRef.current?.blur();
    }
  }, [cancelSearch]);

  function renderSearchControl() {
    const showPlayButton = !isFancyHymnalListVisible() && songClickIsEnabled();
    const widthClassName = showPlayButton ? 'searchPartialWidth' : 'searchFullWidth';
    return (
      <div key='search' id="subHeader">
        <table id="searchTable" className={widthClassName}>
          <tbody key='searchTable'>
            <tr key='searchRow'>
              <td key='searchIcon' className="searchIconCell">
                <div className="searchIcon">
                  <SearchIcon/>
                </div>
              </td>
              <td key='searchDiv' className="searchCell">
                <input
                  key='search'
                  id='song-search-bar'
                  type='text'
                  placeholder='Search'
                  onChange={(event) => setSearchString((event.target as HTMLInputElement).value)}
                  onFocus={() => setTimeout(() => setSearchHasFocus(true))}
                  onBlur={() => setTimeout(() => setSearchHasFocus(false))}
                  onKeyUp={onSearchKeyUp}
                  autoComplete='off'
                  className='searchInput'
                  value={searchString}
                  ref={searchInputRef}
                />
                {/* Note that the built-in search cancel button does not work on iOS */}
              </td>
              <td key='searchCancel' className='cancelSearchCell'>
                <div
                  hidden={!searchHasFocus && searchString === ''}
                  key='cancelSearch'
                  onClick={cancelSearch}
                >
                  <CloseCircleIcon className='cancelSearchIcon'/>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <PlayAllButton $visible={showPlayButton}>
          <PlayIcon onClick={() => loadList()}/>
        </PlayAllButton>
      </div>
    );
  }

  const scrollableDiv = useRef<HTMLDivElement>(null);

  function resetScrollPosition() {
    if (scrollableDiv.current) {
      scrollableDiv.current.scrollTop = 0;
    }
  }

  function renderFancyHymnalList() {
    return (
      <div>
        <table key="fancyHymnalList" className="hymnalList">
          <tbody key="fancyHymnalListBody">
          {
            allHymnals
              .map((hymnal, index) => {
                const {name, id} = hymnal;
                return (
                  <Fragment key={name}>
                    <tr key={name} className="hymnal" onClick={() => {
                      resetScrollPosition();
                      setCurrentHymnalName(name);
                    }}>
                      <td key="icon" className="hymnalIconContainer">
                        <img
                          className={'hymnalIcon' + (name === 'Tanglewood Hymnal' ? ' hymnalIconBorder' : '')}
                          src={hymnalIcon(name, id)}
                          alt="hymnal icon"
                        />
                      </td>
                      <td key="description" className="hymnalDescription">
                        <HymnalTitle style={{...(!songClickIsEnabled(hymnal) && {color: 'var(--color-text-light)'})}}>
                          {name}
                        </HymnalTitle>
                        <div key="publisher" className="hymnalPublisher">{publishers[name]}</div>
                      </td>
                    </tr>
                    {
                      index === hymnals.length - 1 ?
                        null :
                        <tr key={name + '-divider'}>
                          <td key="empty"/>
                          <td key="divider">
                            <hr className="hymnalDivider"/>
                          </td>
                        </tr>
                    }
                  </Fragment>
                );
              })
          }
          </tbody>
        </table>
      </div>
    );
  }

  function isFancyHymnalListVisible() {
    return !props.weeklySongGroups && !searchString && !currentHymnalName && !props.showFavorites;
  }

  function renderHymnals() {
    if (isFancyHymnalListVisible()) {
      return renderFancyHymnalList();
    }

    createSongList()

    let hymnals: HymnalWithHymns[];
    let displayHymnalTitle = true;
    // TODO(hewitt): eliminate duplicated logic here
    if (!searchString && props.weeklySongGroups) {
      hymnals = props.weeklySongGroups?.map(hymnal => {
        if (hymnal.name !== '') {
          return hymnal;
        }
        const hymns = allHymnals.find(({id}) => id === PrimaryChurchHymnalId)?.hymns ?? [];
        return {...hymnal, hymns};
      });
    } else if (props.showFavorites) {
      hymnals = [favoritesHymnal];
      displayHymnalTitle = false;
    } else if (currentHymnalName && !props.weeklySongGroups) {
      hymnals = allHymnals.filter(hymnal => hymnal.name === currentHymnalName);
      displayHymnalTitle = false;
    } else {
      hymnals = allHymnals;
    }

    const hymnalsToRender = hymnals
        .map(hymnal => ({...hymnal, hymns: getVisibleHymns(hymnal)}))
        .map(hymnal => ({...hymnal, message: hymnal.hymns.length === 0 ? hymnal.message : undefined}))
        .filter(hymnal => hymnal.hymns.length > 0 || hymnal.message);
    let childCounter = 0;
    const lastHymnalNameIsWrapped = hymnalsToRender.length > 0 &&
      hymnalNameShouldBeWrapped(hymnalsToRender[hymnalsToRender.length - 1].name);

    return (
      <table key='this-week-table' className='this-week-table'>
        <tbody key='this-week-body' className='this-week-table-body'>
          {
            Array.from(hymnalsToRender.entries()).map(([headingIndex, hymnal]) => {
              const result = renderHymnalHeadingAndHymns({
                headingIndex,
                headingCount: hymnalsToRender.length,
                firstChildIndex: childCounter,
                hymnal,
                displayHymnalTitle,
                lastHymnalNameIsWrapped,
              });
              const headerCount = displayHymnalTitle ? 1 : 0;
              const ellipsisCount = 1;
              const messageCount = 1;
              childCounter += hymnal.hymns.length > 0
                ? Math.min(hymnal.hymns.length + headerCount, maxSearchResultsPerHymnal + ellipsisCount + headerCount)
                : messageCount + headerCount;
              return result;
            })
          }
        </tbody>
      </table>
    );
  }

  const onBack = useCallback(() => {
    setPlaylist(undefined);
    if (getHymnFromUrl(search)) {
      window.history.back();
    }
  }, [setPlaylist, search]);

  function handleClick(hymnal: Hymnal, hymn: Hymn) {
    if (!songClickIsEnabled(hymnal)) {
      return;
    }
    setPlaylist([hymn]);
  }

  function loadList() {
    if (!songClickIsEnabled()) {
      return;
    }
    if (songList.length > 0) {
      setPlaylist(songList)
    }
  }

  // When the browser navigates back to the song list from a single song,
  // the search string is cleared, but the playlist still contains a single song.
  // If the playlist contains multiple songs, it is not possible to navigate
  // back in the browser because we do not navigate to a new URL.
  // This is because the single song attributes are passed through the URL ,
  // but multiple songs are passed to the PlayerPage via the "playlist" prop
  const navigatedBackToHymnsList = playlist &&
    playlist.length === 1 && !new URLSearchParams(search).get('title');

  if (playlist && !navigatedBackToHymnsList) {
    return (
      <PlayerPage hymns={playlist} onBack={onBack} />
    );
  }

  //if there are search parameters, set state to search param hymn
  //meaning on refresh it immediately navigates back to song you were just on
  //using the contents of the browser search bar

  if (getHymnFromUrl(search)) {
    setPlaylist(getHymnFromUrl(search));
  }

  const searchInputElement = document.getElementById('song-search-bar')
  const hymnsTableElement = document.getElementById('song-list')
  hymnsTableElement?.addEventListener("touchmove", () => searchInputElement?.blur())
  const onHymnalBack = singleHymnal ? () => {
    resetScrollPosition();
    setCurrentHymnalName(undefined)
  } : undefined;
  const suppressSignUp = !props.weeklySongGroups;
  // hide footer in demo mode or if on-screen keyboard visible
  const hideFooter = props.isDemo || (searchHasFocus && isInsideMobileApp());
  const isUploadSongButtonVisible = Boolean(
    church &&
    singleHymnal &&
    currentHymnal?.id === PrimaryChurchHymnalId &&
    (isAdminForCurrentChurch(household) || isInternalUser));

  return (
    <OuterPageContent>
      {searchHasFocus || (searchString !== '') ? undefined : (
        <>
          <Header
            title={title}
            onBack={onHymnalBack}
            suppressSignUp={suppressSignUp}
            isUploadSongButtonVisible={isUploadSongButtonVisible}
          />
        </>
      )}
      {!props.isDemo && renderSearchControl()}
      {!songClickIsEnabled() && <SubscriptionInvitation />}
      <InnerPageContent key='inner' id="song-list" ref={scrollableDiv}>
        {
          (!props.message || allHymnals.length > 0) ? renderHymnals() : null
        }
        {
          (props.message && !searchHasFocus && searchString === '') ? (
            <div key='message' className='message'>
              {props.message}
            </div>
          ) : null
        }
      </InnerPageContent>
      {hideFooter ? undefined : (
        <Footer/>)}
    </OuterPageContent>
  );
}

const SongNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 2px;
`;

const VocalsIconWrapper = styled.div<{ $visible?: boolean; }>`
  display: ${props => props.$visible ? 'inherit' : 'none'};
  flex-shrink: 0;
`;

function SubscriptionInvitation() {
  const {church} = useChurch();
  const {household} = useHousehold();
  const householdToken = household?.token;
  const displayOptIn = householdToken && church?.token && church?.areHouseholdsSubscribed;
  const message = displayOptIn
    ? 'Your church provides full subscriptions to all members.'
    : 'Subscribe your household to play all music.';
  const onOptIn = useCallback(async () => {
    await server_api.optHouseholdIntoChurchSubscription({
      householdToken: ensureExists(householdToken),
      churchToken: ensureExists(church?.token),
    });
    await synchronizeHouseholdWithServer({force: true});
    await synchronizeChurchWithServer({force: true});
  }, [householdToken, church]);
  if (displayOptIn) {
    return (
      <OptInContainer>
        <OptInMessage>{message}</OptInMessage>
        <OptInButton onClick={onOptIn}/>
      </OptInContainer>
    )
  }
  return (
    <SubscribeContainer>
      <SubscribeMessage>{message}</SubscribeMessage>
      <SignUpButton subscribeButton={household?.status === HouseholdStatus.Unsubscribed}/>
    </SubscribeContainer>
  )
}

const OptInContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  gap: 10px;
  font-size: 1.3em;
  text-wrap: wrap;
`

const SubscribeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  padding: 10px 3%;
`

const SubscribeMessage = styled.div`
`

const OptInMessage = styled.div`
  max-width: 21ch;
`

const HymnalHeading = styled.tr`
  font-family: Jost-SemiBold, "Arial", sans-serif;
  font-size: 1.5em;
  text-align: left;
  position: sticky;
  top: 0;
  background-color: var(--color-background);
  cursor: pointer;
`

const HymnalHeadingWithAccordion = styled(HymnalHeading)<{
  $headingIndex: number;
  $headingCount: number;
  $nthChild: number;
  $lastHymnalNameIsWrapped: boolean;
}>`
  &:nth-child(${props => props.$nthChild}) {
    @media screen and (min-height: 400px) {
      top: ${props => props.$headingIndex * 1.75}em;
      bottom: ${props => (props.$headingCount - props.$headingIndex - 1) * 1.75 + (
        props.$lastHymnalNameIsWrapped && props.$headingIndex !== (props.$headingCount - 1) ? 1.5 : 0
      )}em;
    }
  }
`

const HymnalNameRow = styled.td`
  padding-top: 0.5em;
`

const HymnalNameGroup = styled.div`
  display: flex;
  flex-direction: column;
`

// for weekly song lists
const HymnalName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100vw;
`

// for fancy hymnal list
const HymnalTitle = styled.div`
  white-space: normal;
`;

const HymnalNameSecondLine = styled.div`
  font-family: Jost-Regular, 'Arial', sans-serif;
  font-size: 0.7em;
  padding-bottom: 0.5em;
`

const HymnalMessage = styled.td`
  white-space: normal;
  text-align: center;
`

const HymnRow = styled.tr`
  padding-left: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: normal;
`

const EllipsisCell = styled.td`
  font-family: Jost-SemiBold, "Arial", sans-serif;
  letter-spacing: 3px;
`

const ScrollableHymnRow = styled(HymnRow)<{
  $headingIndex: number;
  $nthChild: number;
}>`
  padding-left: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: normal;

  &:nth-child(${props => props.$nthChild}) {
    // TODO(hewitt): This calculation is not quite right, but close enough for now.
    //               The idea is that the first hymn following a header should scroll up to just below the header.
    scroll-margin-top: ${props => (props.$headingIndex + 2) * 2.6 - 1.6}em;
  }
`

const PlayAllButton = styled.div<{$visible?: boolean;}>`
  display: ${props => props.$visible ? 'inherit' : 'none'};
  margin: auto;
  padding-right: 5px;
  padding-top: 3px;
  width: 30px;
  height: 30px; // necessary on Safari (go figure...)
  cursor: pointer;
  fill: var(--color-text);
`

function createFavoritesHymnal({credentials, householdStatus, hymns, navigate}: {
  credentials?: string;
  householdStatus?: HouseholdStatus;
  hymns: Hymn[];
  navigate: NavigateFunction;
}): HymnalWithHymns {
  let message: ReactElement | undefined;
  if (householdStatus !== HouseholdStatus.Subscribed) {
    message = <div>Subscribe household to view favorites</div>;
    hymns = [];
  } else {
    if (!credentials) {
      message = <div>Please <span className="inlineLink" onClick={() => navigate(getUrlForPage(Pages.Settings))}>sign in</span> to
        choose favorites.</div>;
    } else if (hymns.length === 0) {
      message = <div>Open a song to mark it as a favorite</div>;
    } else {
      message = undefined;
    }
  }
  return {id: 0, name: 'Favorites', hymns, message, slug: 'FAVORITES'};
}
