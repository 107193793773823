import React, {useEffect} from 'react';
import './header.css';
import {ReactComponent as BackArrow} from '../assets/arrow-previous-left-icon.svg';
import {ReactComponent as ForwardArrow} from '../assets/arrow-forward.svg';
import {ReactComponent as KebabIcon} from '../assets/ellipsis-vertical.svg';
import styled from 'styled-components/macro';
import {useAppUpdateNeeded} from '../util/billing';
import {SignUpButton} from '../util/sign_up_button';
import {synchronizeHouseholdWithServer} from '../data/use_household';
import {UploadSongButton} from '../util/upload_song_button';

interface Props {
  title: string;
  householdCreation?: boolean; // TODO(hewitt): delete this once the household management dust settles
  isUploadSongButtonVisible?: boolean;
  onBack?: () => void;  //optional callback function for back button in header
  onKebabClick?: () => void;
  onSkipChooseChurch?: () => void;
  suppressSignUp?: boolean;
}

export const Header = ({
  isUploadSongButtonVisible,
  title,
  onBack,
  onKebabClick,
  onSkipChooseChurch,
  suppressSignUp
}: Props) => {
  useEffect(() => {
    void synchronizeHouseholdWithServer();
  }, []);

  const otherButtonsHidden = !onKebabClick && !onSkipChooseChurch;
  const appUpdateNeeded = useAppUpdateNeeded();
  const showSignup = otherButtonsHidden && appUpdateNeeded;
  return (
    <HeaderWrapper key='header'>
      {
        onBack
          ? <BackButtonWrapper><BackArrow className='backButton' onClick={onBack}/></BackButtonWrapper>
          : <LeftPlaceholder/>
      }
      <div className={'title'}>{title}</div>
      <HeaderIcons>
        {onKebabClick && <KebabIcon className="kebabIcon" onClick={() => onKebabClick()}/>}
        {onSkipChooseChurch &&
          <div id='chooseChurchSkip' onClick={onSkipChooseChurch}>
            <div style={{display: 'flex', }}>
              <div style={{marginTop: 7}}>Continue</div>
              <ForwardArrow style={{width: 36, height: 36}}/>
            </div>
          </div>
        }
        {showSignup && !suppressSignUp && <SignUpButton/>}
      </HeaderIcons>
      {isUploadSongButtonVisible && <UploadSongButton />}
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  min-height: 55px;
  height: 55px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-text-light);
  white-space: normal;
  background-color: var(--color-background);
`

const HeaderIcons = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-grow: 1;
  flex-basis: 0;
`;

const LeftPlaceholder = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-grow: 1;
  flex-basis: 0;
`;
