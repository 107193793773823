import React from 'react';
import './verse_bar.css'



export interface Props {
  intro?: boolean;
  verseCount: number;
  verse: string;
  handleClick: (arg1: string, arg2: boolean | undefined) => void;
}

export const VerseBar = (props: Props) => {


  const introText = 'Int';
  const maxVerseCount = 9;

  function renderVerses() {
    return [...Array(maxVerseCount).keys()].map(verseIndex => {
      const isIntro = verseIndex === 0;
      const name = isIntro ? introText : verseIndex.toString();
      const hidden = isIntro ? !props.intro : verseIndex > props.verseCount;
      return (
        <div
          key={verseIndex}
          hidden={hidden}
          className='verse'
          id={name}
          style={name === props.verse ? {backgroundColor: '#e9e9eb'} : {backgroundColor: ''}}
          onClick={() => props.handleClick(verseIndex.toString(), props.intro)}
        >
          {name}
        </div>
      );
    });
  }

  return (
    <div id='verseContainer'>
      {renderVerses()}
    </div>
  )
}