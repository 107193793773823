import React, {useCallback} from 'react';
import {
  EditAllHouseholdInfoQueryString,
  isAdminInvite,
  isHouseholdInvite,
  Message,
  navigateToOnboardingPage
} from './common';
import {Frame} from './frame';
import {Button} from './button';
import {OnboardingPages} from '../../common/pages';
import {useNavigate} from 'react-router-dom';

export const HouseholdStart = () => {
  const navigate = useNavigate();
  const onContinue = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.HouseholdLastName, EditAllHouseholdInfoQueryString);
  }, [navigate]);
  return (
    <Frame
      onboardingPage={OnboardingPages.HouseholdStart}
      mainContent={(
        <Message $widthInChars={22}>
          {
            isAdminInvite()
              ? 'First, let\'s create a household so your family can access the full music library!'
              : 'Let\'s create a household so your family can join!'
          }
        </Message>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
      suppressBackButton={isAdminInvite() || isHouseholdInvite()}
    />
  );
}
