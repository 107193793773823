import {useEffect, useState} from "react";
import * as server_api from "../common/server_api";
import {localStorageGet, LocalStorageKey, localStorageSet} from "./client_local_storage";
import {useLocalStorage} from './use_local_storage';

export function useUserAttributes(): {
  isInternalUser: boolean;
} {
  const [user] = useLocalStorage(LocalStorageKey.User);
  const [isInternalUser, setIsInternalUser] = useState<boolean>(() => Boolean(localStorageGet(LocalStorageKey.IsInternalUser)));

  useEffect(() => {
    if (!user) {
      setIsInternalUser(false);
      return;
    }
    void (async () => {
      try {
        const isInternalUserLocal = await server_api.getIsInternalUser();
        setIsInternalUser(isInternalUserLocal);
        localStorageSet(LocalStorageKey.IsInternalUser, isInternalUserLocal);
      } catch {}
    })();
  }, [user]);

  return {isInternalUser};
}