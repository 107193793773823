import {createContext, useState} from 'react';

export interface AppContextInterface {
  pageAnimation: string;
  suppressBackButton: boolean;
  onBack: undefined | (() => void);
  onClose: undefined | (() => void);

  setPageAnimation: React.Dispatch<React.SetStateAction<string>>;
  setSuppressBackButton: React.Dispatch<React.SetStateAction<boolean>>;
  setOnBack: React.Dispatch<React.SetStateAction<undefined | (() => void)>>;
  setOnClose: React.Dispatch<React.SetStateAction<undefined | (() => void)>>;
}

export const AppContext = createContext<AppContextInterface | null>(null);

interface Props {
  children: React.ReactNode;
}

export function AppContextProvider({ children }: Props) {
  const [pageAnimation, setPageAnimation] = useState('moveToLeftFromRight');
  const [suppressBackButton, setSuppressBackButton] = useState(false);
  const [onBack, setOnBack] = useState<undefined | (() => void)>(undefined);
  const [onClose, setOnClose] = useState<undefined | (() => void)>(undefined);

  const appContext: AppContextInterface = {
    pageAnimation,
    suppressBackButton,
    onBack,
    onClose,

    setPageAnimation,
    setSuppressBackButton,
    setOnBack,
    setOnClose,
  };

  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
}
