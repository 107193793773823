import "./modal.css"
import {ReactComponent as CloseIcon} from "./assets/close.svg";
import {ModalButtons} from './modal_buttons';
import {ReportSongIssue} from './report_song_issue';
import {Hymn} from './sequencer';

export interface Props {
  popup: string;
  close: () => void;
  hymn?: Hymn;
}

export const ModalNotification = (props: Props) => {

  const popup = document.getElementById("modal")
  const backdrop = document.getElementById("modal-backdrop")

  if(props.popup) {
    popup && popup.classList.add("active")
    backdrop && backdrop.classList.add("active")
  }

  function close() {
    props.close()
    popup && popup.classList.remove("active")
    backdrop && backdrop.classList.remove("active")
  }

  let message: string = '';
  switch(props.popup) {
    case 'login-favorites':
      message = 'To add this song to Favorites, please sign into an Individual account in Settings.';
      break;
    case 'login-chooseChurch':
      message = 'To select your church, please sign into an Individual account in Settings.';
      break;
    case 'login-report':
      message = 'To report an issue, please sign into an Individual account in Settings.';
      break;
    case 'report-player':
      message = 'report-player';
      break;
    case 'report-settings':
      message = 'report-settings';
      break;
  }

  return (
    <div>
      <div id="modal"
           style={props.popup === 'report-player' ?
             {width: 350, height: 350} :
             props.popup === 'report-settings' ?
               {width: 350, height: 300} : {width: 220, height: 180}
           }>
        <CloseIcon id="closeIcon" onClick={() => close()}/>
        {props.popup !== 'report-player' &&
        props.popup !== 'report-settings' ?
          <ModalButtons location={'modal'} message={message}/> :
          <ReportSongIssue
            location={props.popup}
            hymn={props.hymn}
            close={() => close()}
          />}
      </div>
      <div id="modal-backdrop" onClick={() => close()}> </div>
    </div>
  )
}