import React, {useCallback} from 'react';
import {Frame} from './frame';
import {Content, Message, navigateToOnboardingPage, setOnboardingHouseholdSurveyResponse} from './common';
import {Button} from './button';
import {OnboardingPages} from '../../common/pages';
import styled from 'styled-components/macro';
import {getPushNotificationsAuthorization, PushNotificationAuthorization} from '../../util/push_notifications';
import {localStorageGet, LocalStorageKey} from '../../data/client_local_storage';
import {useLocalStorage} from '../../data/use_local_storage';
import {IOSAppVersionWithPushNotifications} from '../../util/billing';
import {useNavigate} from 'react-router-dom';

export const AllowNotifications = () => {
  const navigate = useNavigate();

  const onContinue = useCallback(async () => {
    const authorization = await getPushNotificationsAuthorization({requestPermission: true});
    console.log(`push notification authorization: ${authorization}`);
    const optedIntoNotifications = authorization === PushNotificationAuthorization.Authorized
    setOnboardingHouseholdSurveyResponse({optedIntoNotifications});
    const household = localStorageGet(LocalStorageKey.Household);
    navigateToOnboardingPage(navigate, household ? OnboardingPages.ShareFriendsFamily : OnboardingPages.HouseholdStart);
  }, [navigate]);

  const [iOSAppVersion] = useLocalStorage(LocalStorageKey.IOSAppVersion);

  return (
    <Frame
      onboardingPage={OnboardingPages.AllowNotifications}
      mainContent={(
        <>
          <MessageWrapper $widthInChars={20}>
            Awesome, we'll help make sure you're two taps away from singing when it's time.
          </MessageWrapper>
          {iOSAppVersion && iOSAppVersion >= IOSAppVersionWithPushNotifications && (
            <Content>
              <NotificationsImage key='friend' src='/images/notifications.png'/>
            </Content>
          )}
        </>
      )}
      footerContent={(
        <>
          <Button onClick={onContinue}>CONTINUE</Button>
        </>
      )}
      onEnterKeypress={onContinue}
    />
  );
};

// The margins below ensure that the notifications dialog underlay image exactly aligns with the actual dialog on iOS
const MessageWrapper = styled(Message)`
  margin-top: 30px;
`;

const NotificationsImage = styled.img`
  margin-top: 25px;
  width: 70%;
  height: auto;
  opacity: 50%;
`;
