import {UserInfo} from "../../authentication/user_info";
import React from "react";
import "./login.css"

interface Props {
  visible: boolean;
  prompt?: string;
}

export const Login = (props: Props) => (
  <div className="settingsContent">
    <div id="accountContainer">
      <UserInfo visible={props.visible} prompt={props.prompt}/>
    </div>
  </div>
);
