import {ensure, ensureExists} from './util';

export enum SypMusicFileKey {
  Title = 'title',
  Composer = 'composer',
  Author = 'author',
  Harmony = 'harmony',
  Tune = 'tune',
  Voice = 'voice',
  Script = 'script',
  Copyright = 'copyright',
  VerseCount = 'verse_count',
  VocalPartCount = 'vocal_part_count',
  Vocals = 'vocals',
  Status = 'status',

  // measure specific keys
  Measure = 'measure',
  Key = 'key',
  Mode = 'mode',
  Rhythm = 'rhythm',
  Tempo = 'tempo',
  Soprano = 'soprano',
  Alto = 'alto',
  Tenor = 'tenor',
  Bass = 'bass',
  Unison = 'unison',
}

type SypVoiceKey = Extract<SypMusicFileKey,
  SypMusicFileKey.Soprano |
  SypMusicFileKey.Alto |
  SypMusicFileKey.Tenor |
  SypMusicFileKey.Bass |
  SypMusicFileKey.Unison
>;

enum MeasureDivider {value = '|'}

export interface SypMusicFile {
  isUnison?: boolean;
  metadata: SypSongMetadata;
  measures: SypMeasure[];
}

export type SypSongMetadata = {[K in SypMusicFileKey]?: string};

export interface SypMeasure {
  number: number;
  key?: string;
  mode?: string;
  rhythm?: string;
  tempo?: number;
  verseCount?: number;
  soprano: SypNote[];
  alto?: SypNote[];
  tenor?: SypNote[];
  bass?: SypNote[];
  skipValidation?: boolean;
}

export function getSypMeasureBeatCount(measure: SypMeasure, {includeFermatas}: {includeFermatas?: boolean} = {}) {
  const beatCounts = measure.soprano.map(note => getBeatCountForSypNote(note, {includeFermatas}));
  return beatCounts.reduce((partialSum, beatCount) => partialSum + beatCount, 0);
}

export function getTimeSignature(measure: SypMeasure): {beats: number, beatType: number} | undefined {
  if (measure.rhythm === undefined) {
    return undefined;
  }
  const match = measure.rhythm.match(/(?<beats>\d+)\s+:\s+(?<beatType>\d+)/);
  if (!match || !match.groups) {
    throw new Error(`Malformed rhythm "${measure.rhythm}" in measure ${measure.number}`);
  }
  return {beats: Number(match.groups.beats), beatType: Number(match.groups.beatType)};
}

export enum SypStep {A = 'a', B = 'b', C = 'c', D = 'd', E = 'e', F = 'f', G = 'g', Rest = 'R'}

export enum OctaveShift {Up = '+', Down = '-'}

export enum SypAccidental {Sharp = '#', Flat = 'b', Natural = 'n'}

export enum SypNotation {TieWithNextNote = 'TieWithNextNote', SlurWithNextNote = 'SlurWithNextNote'}

export class SypNote {
  step: SypStep;
  denominator: number;
  accidental?: SypAccidental;
  octaveShift?: OctaveShift;
  dotCount?: number;
  fermata?: number;
  notation?: SypNotation;

  constructor({
    step, denominator, accidental, octaveShift, dotCount, fermata, notation
  }: {
    step: SypStep;
    denominator: number;
    accidental?: SypAccidental;
    octaveShift?: OctaveShift;
    dotCount?: number;
    fermata?: number;
    notation?: SypNotation;
  }) {
    this.step = step;
    this.denominator = denominator;
    if (accidental) {
      this.accidental = accidental;
    } else {
      delete this.accidental;
    }
    if (octaveShift) {
      this.octaveShift = octaveShift;
    } else {
      delete this.octaveShift;
    }
    if (dotCount) {
      this.dotCount = dotCount;
    } else {
      delete this.dotCount;
    }
    if (fermata) {
      this.fermata = fermata;
    } else {
      delete this.fermata;
    }
    if (notation) {
      this.notation = notation;
    } else {
      delete this.notation;
    }

    if (!Object.values(SypStep).includes(this.step)) {
      throw new Error(`Unknown SypStep "${this.step}"`);
    }
  }

  get isRest() {
    return this.step === SypStep.Rest;
  }

  get beatCount(): number {
    // avoids decimal arithmetic
    const {numeratorMultiplier, denominatorMultiplier} = this.beatMultiplier;
    return (4 * numeratorMultiplier) / (this.denominator * denominatorMultiplier);
  }

  private get beatMultiplier(): {numeratorMultiplier: number, denominatorMultiplier: number} {
    switch (this.dotCount) {
      case undefined:
      case 0:
        return {numeratorMultiplier: 1, denominatorMultiplier: 1};
      case 1:
        return {numeratorMultiplier: 3, denominatorMultiplier: 2};
      case 2:
        return {numeratorMultiplier: 7, denominatorMultiplier: 4};
      default:
        throw new Error(`Unsupported dot count ${this.dotCount}`);
    }
  }

  toString() {
    return this.step +
      (this.accidental ?? '') +
      (this.octaveShift ?? '') +
      (this.denominator === 4 ? '' : '/' + this.denominator.toString()) +
      (this.dotCount ? '.'.repeat(this.dotCount) : '') +
      (this.fermata ? '(' + this.fermata.toString() + ')' : '') +
      (this.notation === SypNotation.TieWithNextNote ? ' =' : '') +
      (this.notation === SypNotation.SlurWithNextNote ? ' -' : '');
  }
}

export function parseSypMusicFile(content: string, filename?: string): SypMusicFile {
  const metadata: SypSongMetadata = {};
  const measures: SypMeasure[] = [];
  let isUnison: boolean | undefined = undefined;
  let isMultipleMeasuresPerLine = false; // soprano    d-/8 e/8 | f e f g | a/2...
  let voiceNotes: {[voice: string]: Array<SypNote | MeasureDivider>} = {}; // only used for multiple measures per line

  function combineTiesAndSlursWithPriorNotes(noteStrings: string[]) {
    for (let index = 0; index < noteStrings.length; index++) {
      if (index === noteStrings.length - 1) {
        continue;
      }
      if (noteStrings[index + 1] === '=' || noteStrings[index + 1] === '-') {
        noteStrings.splice(index, 2, noteStrings[index] + ' ' + noteStrings[index + 1]);
      }
    }
    return noteStrings;
  }

  for (const line of content.split(/\r?\n/)) {
    if (line.trim().length === 0) {
      continue;
    }
    const match = line.match(/(?<key>\S+)\s+(?<value>.*)/);
    if (!match || !match.groups) {
      throw new Error(`No key value pair found for line: ${line} of file ${filename}`);
    }
    const {key, value} = match.groups as {key: SypMusicFileKey, value: string};
    switch (key) {
      case SypMusicFileKey.Measure:
        if (isMultipleMeasuresPerLine) {
          // for multiple measures per line, there should be no 'measure' keywords.
          throw new Error(`Unexpected "measure" during multiple measures per line in ${filename}`);
        }
        measures.push({number: Number(value), soprano: [], alto: [], tenor: [], bass: []});
        break;
      case SypMusicFileKey.Soprano:
      case SypMusicFileKey.Alto:
      case SypMusicFileKey.Tenor:
      case SypMusicFileKey.Bass:
      case SypMusicFileKey.Unison: {
        if (measures.length === 0) {
          isMultipleMeasuresPerLine = true;
        }
        const rawStrings = value.split(/(\s+)/)
          .map(element => element.trim())
          .filter(element => element.length > 0);
        const noteStrings = combineTiesAndSlursWithPriorNotes(rawStrings);
        const notes = noteStrings
          .map(element => getSypNoteFromString(element));
        if (key === SypMusicFileKey.Unison) {
          isUnison = true;
        }
        const voice = key === SypMusicFileKey.Unison ? SypMusicFileKey.Soprano : key;
        if (isMultipleMeasuresPerLine) {
          if (!(voice in voiceNotes)) {
            voiceNotes[voice] = [];
          }
          voiceNotes[voice].push(...notes);
        } else {
          assertNoMeasureDividers(notes, filename);
          // map unison to soprano
          if (key === SypMusicFileKey.Unison) {
            delete measures[measures.length - 1].alto;
            delete measures[measures.length - 1].tenor;
            delete measures[measures.length - 1].bass;
          } else if (isUnison) {
            throw new Error(`Non-unison part ${key} mixed with unison part`)
          }
          ensureExists(measures[measures.length - 1][voice]).push(...notes);
        }
        break;
      }
      case SypMusicFileKey.Rhythm:
        if (!metadata[key]) {
          metadata[key] = value;
        }
        if (measures.length > 0) {
          measures[measures.length - 1].rhythm = value;
        }
        break;
      case SypMusicFileKey.Key:
        if (!metadata[key]) {
          metadata[key] = value;
        }
        if (measures.length > 0) {
          measures[measures.length - 1].key = value;
        }
        break;
      default:
        metadata[key] = value;
        break;
    }
  }

  if (isMultipleMeasuresPerLine) {
    // TODO(hewitt): First measure number should 0 or 1 depending on whether it is a full measure
    let measureNumber = 0;
    const primaryVoice = isUnison && SypMusicFileKey.Unison in voiceNotes
      ? SypMusicFileKey.Unison : SypMusicFileKey.Soprano;
    const measureDividerCount: number = voiceNotes[primaryVoice]
      .filter(note => note === MeasureDivider.value).length;
    measures.push(...[...Array(measureDividerCount + 1)].map((unused, index) => ({
      number: measureNumber + index,
      [primaryVoice]: [],
      ...(!isUnison && {alto: []}),
      ...(!isUnison && {tenor: []}),
      ...(!isUnison && {bass: []}),
    } as SypMeasure)));
    for (const [voice, notes] of Object.entries(voiceNotes)) {
      let measureIndex = 0;
      for (const note of notes) {
        if (note === MeasureDivider.value) {
          measureIndex += 1;
          continue;
        }
        // @ts-ignore - TypeScript isn't quite up to the complexity of this task yet!
        measures[measureIndex][voice as SypVoiceKey].push(note);
      }
    }
  }

  return {
    ...(isUnison && {isUnison}),
    metadata,
    measures,
  };
}

export function getSypNoteFromString(text: string): SypNote | MeasureDivider {
  if (text === MeasureDivider.value) {
    return text as MeasureDivider;
  }
  const match = text.match(
    /^(?<step>[a-gA-GrR])(?<accidental>[#bn])?(?<octave_shift>[+-])?(\/(?<denominator>\d+(\.\d+)?))?(?<dotCount>\.+)?(\((?<fermata>[0-9]+(\.[0-9]+)?)\))?((?<tie>\s+=)|(?<slur>\s+-))?$/
  );
  if (!match || !match.groups) {
    throw new Error(`Unexpected note value "${text}" in Sing Your Part text file`);
  }
  const {step, accidental, octave_shift, denominator, dotCount, fermata, tie, slur} = match.groups;
  const sypStep = ['r', 'R'].includes(step) ? SypStep.Rest : step.toLowerCase() as SypStep;
  if (!Object.values(SypStep).includes(sypStep)) {
    throw new Error(`Unexpected step "${step}" value in Sing Your Part text file`);
  }
  return new SypNote({
    step: sypStep,
    denominator: denominator === undefined ? 4 : Number(denominator),
    ...(accidental && {accidental: accidental as SypAccidental}),
    ...(octave_shift && {octaveShift: octave_shift as OctaveShift}),
    ...(dotCount && dotCount.length > 0 && {dotCount: dotCount.length}),
    ...(fermata && {fermata: Number(fermata)}),
    ...(tie && {notation: SypNotation.TieWithNextNote}),
    ...(!tie && slur && {notation: SypNotation.SlurWithNextNote}),
  });
}

// A0 = 1, C8 = 88
export function getPianoIndex(step: string, octave: number, accidental?: SypAccidental): number {
  const letterValue: {[key: string]: number} = {'C': 0, 'D': 2, 'E': 4, 'F': 5, 'G': 7, 'A': 9, 'B': 11}
  step = step.toUpperCase();
  if (!(step in letterValue)) {
    throw new Error(`Unexpected pitch step ${step}`);
  }
  return 12 * octave + letterValue[step] + getAccidentalValue(accidental) - 8;
}

// The following default ranges are assumed by SYP voices:
// soprano E4 to D5
// alto    A3 to G4
// tenor   E3 to D4
// bass    A2 to G3
// Going above / below each default range requires an octave shift ('+' / '-')
export function getOctaveShift(voice: SypMusicFileKey, step: string, octave: number): OctaveShift | undefined {
  const pianoIndex = getPianoIndex(step, octave);
  switch (voice) {
    case SypMusicFileKey.Soprano:
      if (pianoIndex < getPianoIndex('E', 4)) {
        return OctaveShift.Down;
      } else if (pianoIndex > getPianoIndex('D', 5)) {
        return OctaveShift.Up;
      } else {
        return undefined
      }
    case SypMusicFileKey.Alto:
      if (pianoIndex < getPianoIndex('A', 3)) {
        return OctaveShift.Down;
      } else if (pianoIndex > getPianoIndex('G', 4)) {
        return OctaveShift.Up;
      } else {
        return undefined
      }
    case SypMusicFileKey.Tenor:
      if (pianoIndex < getPianoIndex('E', 3)) {
        return OctaveShift.Down;
      } else if (pianoIndex > getPianoIndex('D', 4)) {
        return OctaveShift.Up;
      } else {
        return undefined
      }
    case SypMusicFileKey.Bass:
      if (pianoIndex < getPianoIndex('A', 2)) {
        return OctaveShift.Down;
      } else if (pianoIndex > getPianoIndex('G', 3)) {
        return OctaveShift.Up;
      } else {
        return undefined
      }
    default:
      throw new Error(`Unrecognized voice "${voice}" (expecting soprano, alto, tenor or bass)`)
  }
}

function getAccidentalValue(accidental?: SypAccidental): number {
  switch (accidental) {
    case SypAccidental.Flat:
      return -1;
    case SypAccidental.Natural:
    case undefined:
      return 0;
    case SypAccidental.Sharp:
      return 1;
    default:
      throw new Error(`Unexpected accidental value "${accidental}"`);
  }
}

export function getBeatCountForSypNote(note: SypNote, {includeFermatas}: {includeFermatas?: boolean} = {}) {
  const baseCount = 4 / note.denominator;
  const fermataBeats = (includeFermatas && note.fermata) || 0;
  if (note.dotCount === undefined) {
    return baseCount + fermataBeats;
  }
  if (note.dotCount === 1) {
    return (baseCount * 3 / 2) + fermataBeats;
  }
  if (note.dotCount === 2) {
    return (baseCount * 7 / 4) + fermataBeats;
  }
  throw new Error(`Unsupported dot count ${note.dotCount}`);
}

function assertNoMeasureDividers(
  values: Array<SypNote | MeasureDivider>, filename?: string
): asserts values is SypNote[] {
  ensure(countMeasureDividers(values) === 0, `Unexpected measure divider in ${filename}`);
}

function countMeasureDividers(values: Array<SypNote | MeasureDivider>) {
  return values.reduce((accum, value) => value === MeasureDivider.value ? 1 : 0, 0);
}