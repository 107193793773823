import React from "react";
import './song_info.css'
import './report_song_issue.css'


interface Props {
  location: string;
  message: string;
}

export const ModalButtons = (props: Props) => {

  return (
    <div style={{position: "relative"}}>
      <div className="modal-message">
        {props.message}
      </div>
    </div>
  )
}