import * as server_api from '../common/server_api';
import {localStorageGet, LocalStorageKey, localStorageSet} from '../data/client_local_storage';

export async function maybeUpgradeApp() {
  if (window.navigator.serviceWorker) {
    try {
      const appHashes = await server_api.getAppHashes();
      const appVersion = localStorageGet(LocalStorageKey.AppVersion);
      const customMusicHash = localStorageGet(LocalStorageKey.CustomMusicHash);
      const hymnalsHash = localStorageGet(LocalStorageKey.HymnalsHash);

      if (customMusicHash !== appHashes.customMusicHash) {
        localStorageSet(LocalStorageKey.CustomMusicHash, appHashes.customMusicHash);
        console.log('Clearing cached custom music');
        const keys = await caches.keys();
        await Promise.all(keys
          .filter(key => key.includes('custom-music'))
          .map(key => caches.delete(key))
        );
      }

      if (hymnalsHash !== appHashes.hymnalsHash) {
        localStorageSet(LocalStorageKey.HymnalsHash, appHashes.hymnalsHash);
        console.log('Clearing cached hymnals');
        const keys = await caches.keys();
        await Promise.all(keys
          .filter(key => key.includes('hymnals') || key.includes('vocals'))
          .map(key => caches.delete(key))
        );
      }

      if (appVersion !== appHashes.appVersion) {
        localStorageSet(LocalStorageKey.AppVersion, appHashes.appVersion);
        const firstTimeClientLoad = !appVersion;
        if (!firstTimeClientLoad) {
          console.log('INITIATING APP UPGRADE');
          navigator.serviceWorker?.addEventListener('message', serviceWorkerListener);
          // @ts-ignore
          window.navigator.serviceWorker.controller.postMessage('ClearCachesAndReload');
        }
      }
    } catch {
      // ignore network errors - skip upgrade check until network is up
    }
  }
}

function serviceWorkerListener(event: any) {
  if (event.data === 'ReloadPage') {
    window.location.reload();
    navigator.serviceWorker?.removeEventListener('message', serviceWorkerListener);
  }
}
