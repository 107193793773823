import {
  addListener,
  localStorageGet,
  LocalStorageKey,
  localStorageSet,
  LocalStorageType,
  removeListener
} from "./client_local_storage";
import {useCallback, useEffect, useState} from "react";

export function useLocalStorage<T extends LocalStorageKey>(
  key: T,
  defaultOverrideFn?: () => LocalStorageType<T> | undefined
): [
  value: LocalStorageType<T>,
  setValue: (value: LocalStorageType<T>) => void,
] {
  const [overrideFnCalled, setOverrideFnCalled] = useState<boolean>(false);
  const [defaultOverride, setDefaultOverride] = useState<LocalStorageType<T> | undefined>();
  const [value, setValue] = useState<LocalStorageType<T>>(() => localStorageGet(key, defaultOverride));

  // guarantee that the override function is only called once, since it may create a new object every time it is called
  useEffect(() => {
    if (overrideFnCalled) {
      return;
    }
    if (defaultOverrideFn) {
      setDefaultOverride(defaultOverrideFn());
    }
    setOverrideFnCalled(true);
  }, [overrideFnCalled, defaultOverrideFn]);

  useEffect(() => {
    // Set the value again in case it changed between the original useState & the useEffect execution.
    // Fixed an iOS bug where the AppConfig would arrive from the server after the RootPage had observed
    // the default value but before the listener was registered.
    // Caused onboarding page not to show at boot for a new installation because the <RootPage> would not
    // see the new AppConfig value until it was retrieved again from the server 5 minutes later.
    // This `setValue` does not cause a re-render if the value is unchanged.
    setValue(localStorageGet(key, defaultOverride));
    const listener = () => setValue(localStorageGet(key, defaultOverride));
    addListener(key, listener);
    return () => removeListener(key, listener);
  }, [defaultOverride, key]);

  const updateValue = useCallback((newValue: LocalStorageType<T>) => {
    localStorageSet(key, newValue);
  }, [key])

  return [value, updateValue];
}
