import React, {useCallback, useEffect, useState} from 'react';
import {Message, navigateToOnboardingPage, Title} from './common';
import {Button} from './button';
import {Frame} from './frame';
import styled from 'styled-components/macro';
import {localStorageGet, LocalStorageKey} from '../../data/client_local_storage';
import * as server_api from '../../common/server_api';
import {isInsideMobileApp} from '../../util/billing';
import {Household, HouseholdStatus} from '../../common/model';
import {OnboardingPages} from '../../common/pages';
import {synchronizeHouseholdWithServer, useHousehold} from '../../data/use_household';
import {useNavigate} from 'react-router-dom';

export const SubscribeThankYou = () => {
  const {household} = useHousehold();
  const [completed, setCompleted] = useState(() => isInsideMobileApp());
  const [succeeded, setSucceeded] = useState(() => isInsideMobileApp());
  const onboardingPage = OnboardingPages.SubscribeThankYou;
  const navigate = useNavigate();

  useEffect(() => {
    if (completed || !household) {
      return;
    }
    (async () => {
      const result = await connectHouseholdToStripeSubscription(household);
      setCompleted(true);
      setSucceeded(result || household.status === HouseholdStatus.Subscribed);
    })();
  })

  const onContinue = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.Download);
  }, [navigate]);

  const onContactSupport = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.Download);
  }, [navigate]);

  const onStartOver = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.SubscribeStart);
  }, [navigate]);

  if (!household) {
    return (
      <Frame
        onboardingPage={onboardingPage}
        mainContent={(<Message $widthInChars={20}>Could not locate household.</Message>)}
        footerContent={(
          <Button onClick={onStartOver}>START OVER</Button>
        )}
      />
    )
  }

  if (!completed) {
    return (
      <Frame
        onboardingPage={onboardingPage}
        mainContent={(<Message $widthInChars={20}>Please wait while we update your household.</Message>)}
        footerContent={<></>}
      />
    )
  }

  if (!succeeded) {
    return (
      <Frame
        onboardingPage={onboardingPage}
        mainContent={(
          <>
            <Title style={{fontSize: '2em'}}>An error occurred!</Title>
            <Message $widthInChars={22}>
              We apologize for the incovenience.  Please reach out to our support staff and we will be happy to help.
            </Message>
          </>
        )}
        footerContent={(
          <>
            <Button onClick={onContactSupport}>
              {/* iOS app requires that this be an anchor rather than a button to function */}
              <ContactSupport href='mailto:support@crescendosw.com' onClick={onContinue}>CONTACT SUPPORT</ContactSupport>
            </Button>
          </>
        )}
        onEnterKeypress={onContactSupport}
      />
    )
  }

  return (
    <Frame
      onboardingPage={onboardingPage}
      mainContent={(
        <>
          <ThankYouTitle>Thank you for partnering with us!</ThankYouTitle>
          <Message $widthInChars={20}>Let’s push music into all the corners of life together.</Message>
        </>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
      suppressBackButton={true}
    />
  );
};

const ThankYouTitle = styled(Title)`
  font-size: 2.1em;
  line-height: 120%;
  width: 15ch;
  text-wrap: wrap;
  padding-bottom: 50px;
`;

const ContactSupport = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

async function connectHouseholdToStripeSubscription(household: Household): Promise<boolean> {
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('session_id');
  if (sessionId && household?.token) {

    // retry up to 5 times
    for (let i = 0; i < 5; i++) {
      // give Stripe time to call our server webhook, backing off more each time
      await new Promise(r => setTimeout(r, 500 * i));

      // update newly created Stripe database entry
      await server_api.updateHouseholdWithStripeSubscription({sessionId, householdToken: household.token});

      // TODO(hewitt): verify retry on failure -> try bogus URL to repro failure

      // does the database think we are subscribed?  If so, we're done.  Otherwise, keep trying.
      await synchronizeHouseholdWithServer({force: true});
      if (localStorageGet(LocalStorageKey.Household)?.status === HouseholdStatus.Subscribed) {
        return true;
      }
    }
  }
  return false;
}
