import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Message,
  navigateToOnboardingPage,
  PreExistingHouseholdInfoQueryString,
  SubscribeContextQueryString,
  TextInput
} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {isValidEmail} from '../../common/util';
import * as server_api from "../../common/server_api";
import {alert} from '../../util/confirm';
import {localStorageGet, LocalStorageKey, localStorageSet} from '../../data/client_local_storage';
import {synchronizeChurchWithServer} from '../../data/use_church';
import {OnboardingPages} from '../../common/pages';
import {getHouseholdFromServer} from '../../data/use_household';
import {Household, HouseholdStatus} from '../../common/model';
import {useNavigate} from 'react-router-dom';

export const HouseholdFind = () => {
  const emailAddressInput = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    emailAddressInput.current?.focus();
  }, [emailAddressInput]);

  const onContinue = useCallback(async () => {
    const householdEmail = emailAddressInput.current?.value;
    if (!householdEmail || !isValidEmail(householdEmail)) {
      await alert({confirmation: <span>Please enter a valid email address</span>});
      return;
    }
    let householdToken = await server_api.getHouseholdToken({householdEmail});
    let household: Household | undefined = undefined;
    try {
      if (householdToken) {
        household = await getHouseholdFromServer(householdToken);
      }
    } catch {
      await alert({confirmation: <span>
          Network error retrieving household.
          Please make sure that you are connected to the Internet.
          If this problem persists, contact your internet service provider.
      </span>});
      return;
    }
    if (!household) {
      navigateToOnboardingPage(navigate, OnboardingPages.HouseholdNotFound);
      return;
    }
    localStorageSet(LocalStorageKey.Household, household);
    await synchronizeChurchWithServer({force: true});
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has(SubscribeContextQueryString)) {
      if (household.status === HouseholdStatus.Subscribed) {
        navigateToOnboardingPage(navigate, OnboardingPages.SubscribeThankYou);
      } else {
        navigateToOnboardingPage(navigate, OnboardingPages.SubscribePayment);
      }
    } else {
      navigateToOnboardingPage(navigate, OnboardingPages.HouseholdWelcome, PreExistingHouseholdInfoQueryString);
    }
  }, [emailAddressInput, navigate]);

  const [isDisabled, setIsDisabled] = useState<boolean>(() => !localStorageGet(LocalStorageKey.OnboardingHouseholdEmail));
  const onEmailChange = useCallback(() => {
    setIsDisabled(!isValidEmail(emailAddressInput.current?.value));
    localStorageSet(LocalStorageKey.OnboardingHouseholdEmail, emailAddressInput.current?.value);
  }, []);

  return (
    <Frame
      onboardingPage={OnboardingPages.HouseholdFind}
      mainContent={(
        <>
          <Message $widthInChars={18}>What email address was used for the household?</Message>
          <TextInput
            type='email'
            defaultValue={localStorageGet(LocalStorageKey.OnboardingHouseholdEmail)}
            onChange={onEmailChange}
            ref={emailAddressInput
          }/>
        </>
      )}
      footerContent={(
        <Button onClick={onContinue} disabled={isDisabled}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
      containsInputElement={true}
    />
  );
}
