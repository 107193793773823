import {SurveyHouseholdSchedule} from '../onboarding/survey_household_schedule';

export interface Props {
  visible: boolean;
  onBack(): void;
}

export const HouseholdSchedule = ({visible, onBack}: Props) => !visible ? null : (
  <SurveyHouseholdSchedule onBack={onBack}/>
);
