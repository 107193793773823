import React, {useCallback, useEffect} from 'react';
import {Title} from './common';
import {Frame} from './frame';
import styled from 'styled-components/macro';
import {ReactComponent as AppleBadgeIcon} from "../../assets/apple-download-white.svg";
import {ReactComponent as GoogleBadgeIcon} from "../../assets/google-play-download-white.svg";
import {ReactComponent as InternetIcon} from "../../assets/internet.svg";
import {Button} from './button';
import {isInsideMobileApp} from '../../util/billing';
import {maybeUpgradeApp} from '../../util/app_upgrade';
import {OnboardingPages} from '../../common/pages';
import {useNavigate} from 'react-router-dom';

export const Download = () => {
  const navigate = useNavigate();

  useEffect(() => {
    void maybeUpgradeApp();
  }, []);

  const onApple = useCallback(() => {
    window.location.href='https://apps.apple.com/us/app/sing-your-part/id1483413949';
  }, []);
  const onGoogle = useCallback(() => {
    window.location.href='https://play.google.com/store/apps/details?id=singyourpart.app.twa';
  }, []);
  const onWeb = useCallback(() => {
    setTimeout(() => navigate('/'));
  }, [navigate]);

  useEffect(() => {
    // never show download page inside the app
    if (isInsideMobileApp()) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <Frame
      onboardingPage={OnboardingPages.Download}
      mainContent={(
        <Title>Three ways to<br/>Sing Your Part</Title>
      )}
      footerContent={(
        <>
          <OptionButton onClick={onApple} notPrimary={true}><AppleBadge/></OptionButton>
          <OptionButton onClick={onGoogle}><GoogleBadge/></OptionButton>
          <OptionButton onClick={onWeb}>
            <WebButtonContent>
              <InternetIconWrapper/>
              <WebButtonText>On the Web</WebButtonText>
            </WebButtonContent>
          </OptionButton>
        </>
      )}
      onEnterKeypress={onWeb}
      suppressBackButton={true}
      onClose={onWeb}
    />
  );
};

const OptionButton = styled(Button)`
  height: 75px;
`;

const WebButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-family: Jost-SemiBold, sans-serif;
  font-size: 0.75em;
  color: black;
  text-decoration: none;
  padding: 15px 21px;
`;

const AppleBadge = styled(AppleBadgeIcon)`
  fill: var(--color-text);
  width: 200px;
`;

const GoogleBadge = styled(GoogleBadgeIcon)`
  fill: var(--color-text);
  width: 200px;
`;

const InternetIconWrapper = styled(InternetIcon)`
  width: 36px;
  fill: var(--color-text);
`;

const WebButtonText = styled.div`
  color: var(--color-text);
  font-family: Jost-SemiBold, sans-serif;
  font-size: 24px;
`;
