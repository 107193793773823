import React, {useCallback} from 'react';
import {Message} from './common';
import {Frame} from './frame';
import {Button} from './button';
import {useChurch} from '../../data/use_church';
import {OnboardingPages} from '../../common/pages';
import {useNavigate} from 'react-router-dom';

export const ChurchWelcome = () => {
  const {church} = useChurch();
  const navigate = useNavigate();
  const onContinue = useCallback(() => {
    navigate('/');
  }, [navigate]);
  return (
    <Frame
      onboardingPage={OnboardingPages.ChurchWelcome}
      mainContent={(
        <Message $widthInChars={24}>Congratulations, your household is now associated with {church?.name}!</Message>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};
