import styled, {keyframes} from 'styled-components/macro';

const SpinKeyframes = keyframes`
  to { -webkit-transform: rotate(360deg); }
`

export const Spinner = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  /* if spinner track is desired */
  /* border: 3px solid var(--color-spinner-background); */
  border: 3px solid var(--color-background);
  border-radius: 50%;
  border-top-color: var(--color-spinner);
  animation: ${SpinKeyframes} 1s linear infinite;
`
