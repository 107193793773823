import React, {useCallback} from 'react';
import {Frame} from './frame';
import {
  Message,
  navigateToOnboardingPage,
  setOnboardingHouseholdSurveyResponse,
  shareWithMusicLeader, uploadLatestSurveyResponses
} from './common';
import {Button} from './button';
import {useHousehold} from '../../data/use_household';
import {OnboardingPages} from '../../common/pages';
import {useChurch} from '../../data/use_church';
import {useNavigate} from 'react-router-dom';

export const ShareMusicLeader = () => {
  const {household} = useHousehold();
  const {church} = useChurch();
  const navigate = useNavigate();

  const onNextPage = useCallback(async () => {
    if (household?.surveyResponses?.schedule) {
      // since this page can be the tail end of the onboarding process, if the church is chosen at the end
      await uploadLatestSurveyResponses();
      navigate('/');
    } else {
      navigateToOnboardingPage(navigate, OnboardingPages.SurveyHouseholdSchedule);
    }
  }, [household, navigate]);

  const onShare = useCallback(async () => {
    setOnboardingHouseholdSurveyResponse({sharedWithChurch: true});
    await shareWithMusicLeader();
    await onNextPage();
  }, [onNextPage]);

  const onSkip = useCallback(async () => {
    setOnboardingHouseholdSurveyResponse({sharedWithChurch: false});
    await onNextPage();
  }, [onNextPage]);

  return (
    <Frame
      onboardingPage={OnboardingPages.ShareMusicLeader}
      mainContent={(
        <Message $widthInChars={22}>
          No problem!<br/><br/>
          Get your church set up; Share this with anyone in your {church?.type?.toLowerCase() ?? 'church'} to start the conversation.
        </Message>
      )}
      footerContent={(
        <>
          <Button onClick={onShare}>SHARE</Button>
          <Button onClick={onSkip}>MAYBE LATER</Button>
        </>
      )}
      onEnterKeypress={onShare}
    />
  );
};
