import {useCallback} from 'react';
import {localStorageGet, LocalStorageKey, localStorageRemove, localStorageSet} from './client_local_storage';
import * as server_api from '../common/server_api';
import {churchFromChurchString, stringFromChurch} from '../common/server_api';
import {Church, SongList} from '../common/model';
import {useLocalStorage} from './use_local_storage';
import fetch from 'node-fetch';
import {OneDayMs} from '../common/sleep';
import {thisWeekDirName} from '../common/paths';

// TODO(hewitt): Remove these special cases eventualy
// Note that 2024-2025 New Saint Andrews freshman have cached a "Church" with no type from the
// beginning of the school year - arguably we should not remove this hack until June 2025
// unless we retroactively update their cached "Church"
export const specialCaseSchools = ['The Ambrose School', 'New Saint Andrews'];

export function useChurch(): {
  church: Church | undefined
  setChurch: (church: Church | undefined) => void
} {
  const [church, setChurch] = useLocalStorage(LocalStorageKey.Church);

  const setChurchAndPushToServer = useCallback((newChurch: Church | undefined) => {

    if (JSON.stringify(church) === JSON.stringify(newChurch)) {
      return;
    }
    setChurch(newChurch);
    localStorageSet(LocalStorageKey.PushChurch, true);
    void synchronizeChurchWithServer();
  }, [church, setChurch]);

  return {church, setChurch: setChurchAndPushToServer};
}

function getHouseholdChurch(
  householdChurchId: number | undefined,
  householdChurchName: string | undefined
): Church | undefined {
  if (householdChurchId === undefined || householdChurchName === undefined) {
    return undefined;
  }
  const {name, location} = churchFromChurchString(householdChurchName);
  return {id: householdChurchId, name, location};
}

// syncs once per day unless forced, pushing, or the church is missing
export async function synchronizeChurchWithServer({force}: {force?: boolean} = {}) {
  // ignore network errors
  try {
    // TODO(hewitt): Remove 9/2024 - eradicate old church string cache entries (replace with Church object)
    if (typeof localStorageGet(LocalStorageKey.Church) === 'string') {
      localStorageRemove(LocalStorageKey.Church);
      localStorageRemove(LocalStorageKey.PushChurch);
    }

    const household = localStorageGet(LocalStorageKey.Household);
    if (!household?.token) {
      localStorageRemove(LocalStorageKey.Church);
      localStorageRemove(LocalStorageKey.PushChurch);
      return;
    }

    let church = localStorageGet(LocalStorageKey.Church);
    const pushChurch = localStorageGet(LocalStorageKey.PushChurch);

    if (!force && !pushChurch &&
      church?.mostRecentSyncTimestamp && (Date.now() - church.mostRecentSyncTimestamp) < OneDayMs
    ) {
      return;
    }

    // TODO(hewitt): Remove 9/2024 - Migrate from billing church id & church name to household church
    const householdChurchId = localStorageGet(LocalStorageKey.HouseholdChurchId);
    const householdChurchName = localStorageGet(LocalStorageKey.HouseholdChurchName);
    if (!church && householdChurchId) {
      church = getHouseholdChurch(householdChurchId, householdChurchName);
      localStorageSet(LocalStorageKey.Church, church);
    }
    localStorageRemove(LocalStorageKey.HouseholdChurchId);
    localStorageRemove(LocalStorageKey.HouseholdChurchName);

    if (pushChurch) {
      await server_api.setHouseholdChurch(household.token, church);
      const {churchId: _unused, ...householdWithoutChurchId} = household;
      localStorageSet(LocalStorageKey.Household, {...householdWithoutChurchId, ...(church && {churchId: church.id})});
      localStorageRemove(LocalStorageKey.PushChurch);
    }
    const serverChurch = await server_api.getHouseholdChurch(household.token);
    if (serializeChurch(church) !== serializeChurch(serverChurch)) {
      if (serverChurch) {
        serverChurch.mostRecentSyncTimestamp = Date.now();
      }
      localStorageSet(LocalStorageKey.Church, serverChurch);
    }
  } catch { }
}

function serializeChurch(church: Church | undefined): string | undefined {
  if (!church) {
    return undefined;
  }
  // avoid taking into acount the sync timestamp when comparing church contents
  const {mostRecentSyncTimestamp: _unused, ...churchWithoutTimestamp} = church;
  return JSON.stringify(churchWithoutTimestamp);
}

export async function synchronizeWeeklySongLists() {
  await synchronizeChurchWithServer();
  const church = localStorageGet(LocalStorageKey.Church);

  if (!church) {
    localStorageSet(LocalStorageKey.WeeklySongList, undefined);
    return;
  }

  // ignore network errors
  try {
    const churchManifest = `/${thisWeekDirName}/${stringFromChurch(church)}.json`;
    const response = await fetch(churchManifest);
    const songsForWeek = await response.json() as SongList[];
    const previousSongsForWeek = localStorageGet(LocalStorageKey.WeeklySongList);
    // TODO(hewitt): Remove hack for The Ambrose School
    function hideEventDates() {
      return songsForWeek.map(songList => {
        const {date, ...songListWithoutDate} = songList;
        // NOTE: Dangerous -> we are omitting the required `date` property...
        return songListWithoutDate as any as SongList;
      });
    }

    // only update storage if something changed
    if (JSON.stringify(songsForWeek) !== JSON.stringify(previousSongsForWeek)) {
      localStorageSet(LocalStorageKey.WeeklySongList, specialCaseSchools.includes(church.name) ? hideEventDates() : songsForWeek);
    }
  } catch { }
}
