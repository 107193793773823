import React from 'react';
import styled from 'styled-components/macro';
import '../settings_page.css'
import {useLogMessages} from '../../util/logging';

export const LoggingPage = () => {
  const {logMessages} = useLogMessages();

  return (
    <div className="settingsContent">
      <ScrollContainer>
        {
          logMessages.map(({index, message}) => {
            return(
              <Message key={index}>{message}</Message>
            )
          })
        }
      </ScrollContainer>
    </div>
  )
};

const ScrollContainer = styled.code`
  position: absolute;
  width: 100vw;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  justify-items: start;
`

const Message = styled.div`
  text-align: left;
`
