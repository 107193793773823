import React, {useCallback} from 'react';
import {
  EditAllHouseholdInfoQueryString,
  Message,
  navigateToOnboardingPage,
  SubscribeContextQueryString
} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {OnboardingPages} from '../../common/pages';
import {useNavigate} from 'react-router-dom';

export const SubscribeFindHousehold = () => {
  const navigate = useNavigate();
  const onFindHousehold = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.HouseholdFind, SubscribeContextQueryString);
  }, [navigate]);
  const onNewHousehold = useCallback(() => {
    navigateToOnboardingPage(
      navigate,
      OnboardingPages.HouseholdLastName,
      SubscribeContextQueryString,
      EditAllHouseholdInfoQueryString,
    );
  }, [navigate]);
  return (
    <Frame
      onboardingPage={OnboardingPages.SubscribeFindHousehold}
      mainContent={(
        <Message $widthInChars={18}>Have you already created a household?</Message>
      )}
      footerContent={(
        <>
          <Button onClick={onFindHousehold}>I ALREADY HAVE A HOUSEHOLD</Button>
          <Button onClick={onNewHousehold}>CREATE NEW HOUSEHOLD</Button>
        </>
      )}
      onEnterKeypress={onFindHousehold}
    />
  );
};
