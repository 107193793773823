import assert from "assert";

export type DateString = string; // "YYYY-MM-DD"

export function createDateString(year: number, month: number, day: number): DateString {
  const yearString = year.toString();
  const monthString = month.toString().padStart(2, '0');
  const dayString = day.toString().padStart(2, '0');
  return `${yearString}-${monthString}-${dayString}`;
}

export function dateStringFromDate(date: Date): DateString {
  // note that month is zero based
  return createDateString(date.getFullYear(), date.getMonth() + 1, date.getDate());
}

export function splitDateString(date: DateString): {year: number, month: number; day: number} | undefined {
  const match = date.match(/^(?<year>\d\d\d\d)-(?<month>\d\d)-(?<day>\d\d)/);
  if (!match || !match.groups) {
    return;
  }
  const {year, month, day} = match.groups;
  // note that month is zero based
  return {year: Number(year), month: Number(month) - 1, day: Number(day)};
}

export function validateDateString(date: DateString) {
  assert(splitDateString(date), `Malformed date string "${date}", expecting "YYYY-MM-DD"`);
}

export function dateFromString(date?: DateString): Date | undefined {
  if (!date) {
    return;
  }
  const match = splitDateString(date);
  if (!match) {
    return;
  }
  return new Date(match.year, match.month, match.day);
}