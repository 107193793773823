export function forwardConsoleLogToIOS() {
  const log = console.log.bind(console);
  console.log = (...args) => {
    log(...args);
    const message = args.reduce((result, value) => result + value, '');
    // @ts-ignore
    window.webkit?.messageHandlers?.consoleLog?.postMessage({message});
  };
  console.log('Connected console.log() to iOS logging');
}
