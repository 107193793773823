import React, {useCallback, useEffect} from 'react';
import {Message, navigateToOnboardingPage, SubscribeContextQueryString, Title} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {useHousehold} from '../../data/use_household';
import styled from 'styled-components/macro';
import {OnboardingPages} from '../../common/pages';
import {HouseholdStatus} from '../../common/model';
import {maybeUpgradeApp} from '../../util/app_upgrade';
import {useNavigate} from 'react-router-dom';

export const SubscribeStart = () => {
  const {household} = useHousehold();
  const navigate = useNavigate();

  useEffect(() => {
    void maybeUpgradeApp();
  }, []);

  const onContinue = useCallback(() => {
    if (household) {
      navigateToOnboardingPage(navigate, OnboardingPages.SubscribePayment, SubscribeContextQueryString);
    } else {
      navigateToOnboardingPage(navigate, OnboardingPages.SubscribeFindHousehold, SubscribeContextQueryString);
    }
  }, [household, navigate]);

  useEffect(() => {
    if (household && household.status === HouseholdStatus.Subscribed) {
      navigateToOnboardingPage(navigate, OnboardingPages.SubscribeThankYou);
    }
  }, [household, navigate]);

  return (
    <Frame
      onboardingPage={OnboardingPages.SubscribeStart}
      mainContent={(
        <>
          <Partner key='partner'>Partner with us!</Partner>
          <Message $widthInChars={24}>
            By subscribing, you gain access to our full hymnal library
            and join us as we push good music into all the corners of life.
          </Message>
        </>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};

const Partner = styled(Title)`
  font-size: 2.1em;
  padding-bottom: 2em;
`;
