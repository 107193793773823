import styled from 'styled-components/macro';
import {useCallback} from 'react';

export const FailurePage = () => {
  const onRestart = useCallback(() => {
    window.location.href = '/';
    return false;
  }, []);

  return (
    <FailureWrapper>
      <FailureContent>
        <span>
          App failed to load.<br/>
          A crash report has been sent to our engineers.<br/>
          <a href="mailto:support@crescendosw.com" style={{color: "lightblue", textDecoration: "underline"}}>Click
            here</a> to reach out to support if you have questions or concerns.
        </span>
      </FailureContent>
      <RestartButton onClick={onRestart}>Restart Application</RestartButton>
    </FailureWrapper>
  );
};

const FailureWrapper = styled.div`
  font-family: Jost-SemiBold, Arial, sans-serif;
  display: table-cell;
  margin: 0;
  padding: 0;

  text-align: center;
  vertical-align: middle;
  background-image: linear-gradient(#5200BB, black);
`;

const FailureContent = styled.div`
  /*border: 5px solid red;*/
  margin: auto;
  /* IMAGE */
  /*height: 200px;*/
  /*width: 200px;*/
  font-size: 1.5rem;
  color: white;
  padding: 0 5%;
`;

const RestartButton = styled.button`
  font-size: 1.5rem;
  margin-top: 10%;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: white;
  color: black;
`;