import React, {useCallback, useEffect} from 'react';
import {
  clearOnboardingLocalStorage,
  Message,
  navigateToOnboardingPage, PreExistingHouseholdInfoQueryString
} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {useChurch} from '../../data/use_church';
import {OnboardingPages} from '../../common/pages';
import * as server_api from '../../common/server_api';
import {synchronizeHouseholdWithServer, useHousehold} from '../../data/use_household';
import {localStorageGet, LocalStorageKey} from '../../data/client_local_storage';
import {useNavigate} from 'react-router-dom';

export const HouseholdWelcome = () => {
  const {household} = useHousehold();
  const {church} = useChurch();
  const navigate = useNavigate();
  useEffect(() => {
    void (async () => {
      if (household?.token) {
        const responses = localStorageGet(LocalStorageKey.OnboardingHouseholdSurveyResponses);
        if (Object.keys(responses).length > 0) { // don't store an empty object in testing
          await server_api.recordHouseholdSurveyResponses({
            householdToken: household?.token,
            responses,
          });
        }
        await synchronizeHouseholdWithServer({force: true}); // initializes household.surveyResponses, church
      }
      clearOnboardingLocalStorage();
    })();
  }, [household]);
  const onContinue = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const isPreExistingHousehold = params.has(PreExistingHouseholdInfoQueryString);
    if (!household?.surveyResponses && !isPreExistingHousehold) {
      navigateToOnboardingPage(navigate, OnboardingPages.Questions);
      return;
    }
    if (church || household?.surveyResponses?.choseOrganization !== undefined || isPreExistingHousehold) {
      navigate('/');
      return;
    }
    navigateToOnboardingPage(navigate, OnboardingPages.ChurchChoice);
  }, [church, household, navigate]);
  return (
    <Frame
      onboardingPage={OnboardingPages.HouseholdWelcome}
      mainContent={(
        <Message $widthInChars={20}>Welcome, your household is ready to sing!</Message>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
      suppressBackButton={true}
    />
  );
};
