import React, {useCallback} from 'react';
import {Frame} from './frame';
import {ButtonContent, Message, navigateToOnboardingPage} from './common';
import {Button, LeftAlignedButton} from './button';
import {ExpectedUsage} from '../../common/model';
import {useLocalStorage} from '../../data/use_local_storage';
import {LocalStorageKey} from '../../data/client_local_storage';
import styled from 'styled-components/macro';
import {OnboardingPages} from '../../common/pages';
import {useNavigate} from 'react-router-dom';

interface Option {
  prompt: string;
  expectedUsage: ExpectedUsage;
}

export const SurveyExpectedUsage = () => {
  const navigate = useNavigate();

  const onContinue = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.SurveyTimeInvestment);
  }, [navigate]);

  const options: Option[] = [
    {prompt: 'Post my church’s song lists', expectedUsage: ExpectedUsage.PostSongLists},
    {prompt: 'For choir or praise team', expectedUsage: ExpectedUsage.Choir},
    {prompt: 'Start a singing small group', expectedUsage: ExpectedUsage.SmallGroup},
    {prompt: 'To sing at home more', expectedUsage: ExpectedUsage.Home},
    {prompt: 'Learn to sing harmony', expectedUsage: ExpectedUsage.Harmony},
  ];

  const [selectedOptions, setSelectedOptions] = useLocalStorage(LocalStorageKey.OnboardingExpectedUsage);

  const onChooseOption = useCallback((expectedUsage: ExpectedUsage) => {
    return () => {
      const isSelected = selectedOptions && selectedOptions.includes(expectedUsage);
      const newOptions = isSelected
        ? selectedOptions.filter(option => option !== expectedUsage)
        : [...(selectedOptions ?? []), expectedUsage];
      setSelectedOptions(newOptions);
    }
  }, [selectedOptions, setSelectedOptions]);

  return (
    <Frame
      onboardingPage={OnboardingPages.SurveyExpectedUsage}
      mainContent={(
        <>
          <Message $widthInChars={24}>How do you want to use this app? (select all that apply)</Message>
          <ButtonContent>
            {
              options.map((option) => (
                <LeftAlignedButton
                  key={option.expectedUsage}
                  onClick={onChooseOption(option.expectedUsage)}
                  notPrimary={true}
                  selected={selectedOptions?.includes(option.expectedUsage)}
                >
                  <OptionWrapper>
                    {option.prompt}
                  </OptionWrapper>
                </LeftAlignedButton>
              ))
            }
          </ButtonContent>
        </>
      )}
      footerContent={(
        <Button onClick={onContinue} disabled={!selectedOptions || selectedOptions.length === 0}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};

const OptionWrapper = styled.div`
  padding: 10px 25px;
`;
