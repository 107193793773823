import {useEffect, useState} from 'react';

type LogMessage = {index: number, message: string};

const LogMessageLimit = 1000;
const LogMessages: LogMessage[] = [];
const RefreshCallbacks: Set<() => void> = new Set();

let messageIndex = 0;

export function recordConsoleLog() {
  const log = console.log.bind(console);

  console.log = (...args) => {
    log(...args);

    const date = new Date().toLocaleString();
    const message = args.reduce((result, value) => result + value, '');
    const dateAndMessage = date + ': ' + message;

    LogMessages.unshift({index: messageIndex++, message: dateAndMessage});
    LogMessages.length = Math.min(LogMessages.length, LogMessageLimit);

    for (const callback of RefreshCallbacks.values()) {
      callback();
    }
  };
}

function registerRefreshCallback(callback: () => void) {
  RefreshCallbacks.add(callback);
}

function unregisterRefreshCallback(callback: () => void) {
  RefreshCallbacks.delete(callback);
}

export function useLogMessages(): {logMessages: LogMessage[], logMessageLength: number} {
  const [logMessages, setLogMessages] = useState(() => LogMessages);
  const [logMessageLength, setLogMessageLength] = useState(() => LogMessages.length);
  useEffect(() => {
    const callback = () => {
      setLogMessages(LogMessages);
      setLogMessageLength(LogMessages.length);
    }
    registerRefreshCallback(callback)
    return () => {
      unregisterRefreshCallback(callback);
    }
  }, []);
  return {logMessages, logMessageLength};
}
