import React, {useCallback, useEffect, useRef} from 'react';
import {Message, navigateToOnboardingPage, TextInput} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {OnboardingPages} from '../../common/pages';
import styled from 'styled-components/macro';
import {useChurch} from '../../data/use_church';
import {getPlatform, Platform} from '../../util/platform';
import {useLocalStorage} from '../../data/use_local_storage';
import {LocalStorageKey} from '../../data/client_local_storage';
import {beginFileUpload} from './custom_music_upload';
import {useNavigate} from 'react-router-dom';

// https://www.freecodecamp.org/news/formdata-explained/
// "dropzone" is a good search keyword: https://www.npmjs.com/package/react-dropzone-uploader
// see https://medium.com/@blessingmba3/building-a-file-uploader-with-react-11dba6409480

export const CustomMusicUploadSongName = () => {
  const [songName, setSongName] = useLocalStorage(LocalStorageKey.MusicUploadSongName);
  const [, setPsalmNumber] = useLocalStorage(LocalStorageKey.MusicUploadPsalmNumber);
  const songNameInput = useRef<HTMLInputElement>(null);
  const {church} = useChurch();
  const fileChooserInput = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setPsalmNumber(undefined); // clear previously entered psalm number
    songNameInput.current?.focus();
  }, [setPsalmNumber, songNameInput]);

  const onSongNameChanged = useCallback(() => {
    setSongName(songNameInput.current?.value ?? '');
  }, [songNameInput, setSongName]);

  const onUpload = useCallback(() => {
    fileChooserInput?.current?.click();
  }, []);

  const onAddPsalmNumber = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.CustomMusicUploadPsalmNumber);
  }, [navigate]);

  const onFileChosen = useCallback(() => {
    const file = fileChooserInput?.current?.files?.[0];
    if (!file || !church || !songName) {
      return;
    }
    void beginFileUpload(navigate, file);
  }, [church, songName, navigate]);

  const squeezePageHeight = getPlatform() === Platform.Mobile;

  return (
    <Frame
      onboardingPage={OnboardingPages.CustomMusicUploadSongName}
      mainContent={(
        <>
          <input type="file" id="imgupload" style={{display: "none"}} ref={fileChooserInput} onChange={onFileChosen}/>
          <CustomMusicMessage $widthInChars={24}>What is the name of the song?</CustomMusicMessage>
          <CustomMusicInput
            type='text'
            enterKeyHint='next'
            onChange={onSongNameChanged}
            ref={songNameInput}
            defaultValue={songName}
            $squeezePageHeight={squeezePageHeight}
          />
        </>
      )}
      footerContent={(
        <>
          <Button onClick={onUpload} disabled={!songName}>UPLOAD</Button>
          <Button onClick={onAddPsalmNumber} disabled={!songName}>ADD PSALM NUMBER</Button>
        </>
      )}
      onEnterKeypress={onUpload}
      containsInputElement={true}
      containsMultipleButtons={true}
    />
  );
};

const CustomMusicMessage = styled(Message)`
  padding-bottom: 0;
`;

const CustomMusicInput = styled(TextInput)<{$squeezePageHeight: boolean}>`
  padding-top: ${props => props.$squeezePageHeight ? '10px' : '40px'};
`;
