import {Hymn} from "./sequencer";
import {HymnIssue} from "./common/model";
import {isInsideAppleAppStoreApp} from './util/billing';
import styled from 'styled-components/macro';
import {userVisibleSongNumber} from './common/util';

export const churchHymnalIconPath = '/images/open-book.svg';
export const favoritesHymnalIconPath = '/images/bookmark.png';

export function getHymnFromUrl(search?: string): Hymn[] | undefined{
  if (!search) {
    return;
  }
  const searchParams = new URLSearchParams(search);
  const hymnTitle = searchParams.get('title');
  const hymnNumber = Number(searchParams.get('number'));
  const hymnalName = searchParams.get('hymnal');
  const basePath = searchParams.get('basePath') as (string | undefined);
  const issue = searchParams.get('issue') as (HymnIssue | undefined);
  const extensionsRaw = searchParams.get('extensions') as (string | undefined);

  if(!hymnTitle || !hymnNumber || !hymnalName) {
    return;
  }

  let extensions: string[] | undefined;
  try {
    extensions = extensionsRaw ? (JSON.parse(extensionsRaw) as string[] | undefined) : undefined;
  } catch {}
  let hymn: Hymn;

  const correctedHymnTitle = hymnTitle.replace(/_/g, ' ');
  const correctedHymnalName = hymnalName.replace(/_/g, ' ');
  const psalmName = searchParams.get('psalm');

  if (psalmName) {
    const correctedPsalmName = psalmName.replace(/_/g, ' ');

    hymn = {
      hymnal: correctedHymnalName,
      title: correctedHymnTitle,
      number: hymnNumber,
      psalm: correctedPsalmName,
      ...(basePath && {basePath}),
      ...(issue && {issue}),
      ...(extensions && {extensions}),
    };
  } else {
    hymn = {
      hymnal: correctedHymnalName,
      title: correctedHymnTitle,
      number: hymnNumber,
      ...(basePath && {basePath}),
      ...(issue && {issue}),
      ...(extensions && {extensions}),
    };
  }

  return [hymn];
}

export function getSongSlugForHymn(hymn: Hymn): string | undefined {
  const songNumber = userVisibleSongNumber(hymn.number);
  let prefix: string;
  if (hymn.basePath) {
    const churchId = hymn.hymnal.match(/^(?<churchId>\d+)/)?.groups?.churchId;
    if (!churchId) {
      console.log(`ERROR: Cannot parse church Id from hymnal "${hymn.hymnal}" for hymn with basePath ${hymn.basePath}`);
      return undefined;
    }
    prefix = `church/${churchId}`;
  } else {
    // TODO(hewitt): determine correct hymnal slug
    const slug = 'cc2020';
    prefix = `hymnal/${slug}`;
  }
  return `${prefix}/song/${songNumber}`;
}

export const OuterPageContent = styled.div`
  display: flex;
  flex-flow: column;
  // Account for mobile nav bar height (iOS Safari fails to access the CSS variable, so short circuit)
  height: ${isInsideAppleAppStoreApp() ? '100vh' : 'var(--view-height, 100vh)'};
`

export const InnerPageContent = styled.div`
  flex: auto;
  overflow-y: auto;
  overflow-x: clip;
  white-space: nowrap;
  max-width: 100vw;
`

export const SettingsTransitionWrapper = styled(OuterPageContent)<{$left?: string;}>`
  position: fixed;
  left: ${props => props.$left};
  width: 100%;
  top: 0;
  bottom: 0;
  transition: left 200ms;
  transition-timing-function: ease-out;
`;

export const PlainLinkOutlined = styled.a`
  margin-top: 10px;
  padding: 5px 10px;
  border: 3px solid var(--color-text);
  border-radius: 15px;
  cursor: pointer;
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.3em;
  text-decoration: none;
  width: fit-content;

  &:hover {
    background-color: lightgray;
  }
`;
