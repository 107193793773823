import styled from 'styled-components/macro';

// TODO(hewitt): should be button props + children
interface AnyProps {
  [key: string]: any;
}

interface Props extends AnyProps {
  notPrimary?: boolean;
  leftAlign?: boolean;
  selected?: boolean;
  onClick: () => void;
}

export const Button = ({children, notPrimary, leftAlign, selected, onClick, ...buttonProps}: Props) => {
  if (notPrimary) {
    return (
      // Note: Delaying the click with setTimeout() fixes page transition animation, but breaks input focus on iOS
      <ButtonWrapperBase $selected={selected} onClick={onClick} {...buttonProps}>
        <ButtonContent>
          {children}
        </ButtonContent>
      </ButtonWrapperBase>
    );
  }
  return (
    <ButtonWrapper onClick={onClick} $selected={selected} {...buttonProps}>
      <ButtonContent>
        {children}
      </ButtonContent>
    </ButtonWrapper>
  );
}

const ButtonWrapperBase = styled.button<{$selected?: boolean}>`
  
  /* the button itself is effectively the drop shadow behind the child div */
  font-family: Jost-SemiBold, sans-serif;
  height: 3em;
  width: 90%;
  cursor: pointer;
  border: none;
  background-color: ${props => props.$selected ? 'var(--color-button-selected-border)' : 'var(--color-button-border)'};
  border-radius: 15px;

  /* the child div is lifted up and colored with a border */
  & > div {
    transform: translateY(-4px);
    color: var(--color-text-button-onboarding);
    background-color: ${props => props.$selected ? 'var(--color-button-selected-background)' : 'var(--color-background)'};
    border: solid 2px ${props => props.$selected ? 'var(--color-button-selected-border)' : 'var(--color-button-border)'};
    border-radius: 15px;
  }

  /* on click, the child div drops down */
  &:active > div {
    transform: translateY(0);
  }
  /* disabled button remains dropped down & gray */
  &:disabled > div {
    transform: translateY(0);
    color: var(--color-text-light);
    background-color: var(--color-background-button-disabled);
    border: none;
  }
`;

const ButtonWrapper = styled(ButtonWrapperBase)`
  // because subclass declarations override base class declarations, we must increase the specificity
  // to avoid trumping base class declarations for disabled & active
  
  &:first-of-type:not(:disabled) {
    background-color: var(--color-button-border-dark);
  }

  &:first-of-type:not(:disabled) > div {
    color: var(--color-text-button-onboarding-primary);
    background-color: var(--color-background-button-onboarding);
    border: solid 2px var(--color-button-border-dark);
  }

  &:first-of-type:not(:disabled):not(:active) > div {
    transform: translateY(-4px);
  }
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const LeftAlignedButton = styled(Button)`
  height: max-content;
  & > div {
    color: var(--color-text);
    justify-content: start;
  }
`;
