import {useCallback} from 'react';
import styled from 'styled-components/macro';
import {ReactComponent as AddIcon} from '../assets/add.svg';
import {navigateToOnboardingPage} from '../pages/onboarding/common';
import {OnboardingPages} from '../common/pages';
import {useNavigate} from 'react-router-dom';

export const UploadSongButton = () => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.CustomMusicUploadSongName);
  }, [navigate]);

  return (
    <UploadSongButtonWrapper onClick={onClick}>
      <AddIconWrapper/>
    </UploadSongButtonWrapper>
  );
}

const UploadSongButtonWrapper = styled.button`
  background-color: var(--color-background-button-onboarding);
  position: absolute;
  right: 0;
  margin: 10px;
  padding: 2.5px;
  border-radius: 30px;
`;

const AddIconWrapper = styled(AddIcon)`
  width: 30px;
  color: var(--color-background);
`;
