import React, {useCallback, useEffect} from "react";
import jwt_decode from "jwt-decode";
import './google_login.css'
import '../pages/settings_page.css'
import * as server_api from "../common/server_api";
import {useAuthenticationHelper} from "./use_authentication_helper";
import {ReactComponent as GoogleLogo} from "../assets/logo-google.svg";

export function isGoogleCredentials(credentials) {
    return credentials.startsWith('Google');
}

export function getGooglePicture(credentials) {
    return jwt_decode(credentials).picture;
}

function GoogleLogin(props) {
    const {setUserAndCredentials} = useAuthenticationHelper();

    let signInDiv = props.location === "settings" ? "signInDivSettings" :
        props.location === "welcome" ? "signInDivWelcome" : "signInDivModal"

    const handleCallbackResponse = useCallback((response) => {
        // todo: display meaningful error message if unable to log into our server
        const loginCredentials = `Google ${response.credential}`;
        server_api.upsertUser(loginCredentials).then(({token}) => {
            const {name, email} = jwt_decode(response.credential);
            setUserAndCredentials({name, email}, loginCredentials, token);
        })
    }, [setUserAndCredentials]);

    useEffect( () => {
        void (async () => {
            // occasionally, on safari & firefox the 'google' global is not defined by this point
            // likely because the accounts.google.com script in index.html is marked async & defer
            // thus, we sleep 0s, 250ms, and 1s before giving up
            for (const i of [0, 1, 4]) {
                try {
                    /* global google */
                    google.accounts.id.initialize({
                        client_id: "261418325124-gbbhvui42rgk8akd6vu029oeva22v3ab.apps.googleusercontent.com",
                        callback: handleCallbackResponse,
                    })

                    let parent = document.getElementById(signInDiv);

                    switch (signInDiv) {
                        default:
                        case "signInDivWelcome":
                            google.accounts.id.renderButton(parent, {width: "300px"});
                            break;
                        case "signInDivModal":
                            google.accounts.id.renderButton(parent, {width: "45px", type: "icon"});
                            break;
                        case "signInDivSettings":
                            google.accounts.id.renderButton(parent, {width: "250px"});
                            break;
                    }
                    break;
                } catch {
                }
                await new Promise(r => setTimeout(r, i * 250))
            }
        })();
    })

    return (
        <div style={{position: "relative"}}>
            <div id={signInDiv} />
            <div  style={props.location === "settings" ? {
                position: "absolute",
                top: "0",
                right: "0",
                left: "25px",
                bottom: "0",
                width: "248px",
                height: "40px",
                lineHeight: "40px",
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: "5px",
                pointerEvents: "none",
                boxShadow: "1px 1px 3px #888888"

            } : props.location === "welcome" ? {
                position: "absolute",
                top: "0",
                right: "0",
                left: "10px",
                bottom: "0",
                width: "298px",
                height: "40px",
                lineHeight: "40px",
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: "5px",
                pointerEvents: "none",
                boxShadow: "1px 1px 3px #888888"

            } : {
                position: "absolute",
                top: "181px",
                right: "0",
                left: "45px",
                width: "43px",
                height: "43px",
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: "5px",
                pointerEvents: "none",
                boxShadow: "1px 1px 3px #888888"
            }}>

                <div style={{display: "flex"}}>
                    <GoogleLogo style={props.location !== "modal" ? {
                            width: "25px",
                            transform: "translate(8px, 8px)",
                        } :
                        {
                            width: "28px",
                            height: "28px",
                            transform: "translate(7px,7px)",
                        }}/>

                    {props.location !== "modal" && <div
                        style={props.location === "settings" ?
                            {marginLeft: "40px", fontWeight: "500", fontSize: "15px"} :
                            {marginLeft: "60px", fontWeight: "500", fontSize: "15px"}}>Sign in with Google</div>}
                </div>
            </div>
        </div>

    );
}

export default GoogleLogin;