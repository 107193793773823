import React, {useCallback, useEffect, useState} from 'react';
import {getHymnFromSongListEntry, HymnalWithHymns, HymnsList} from './hymns_list';
import '../shared.css';
import {
  specialCaseSchools,
  synchronizeWeeklySongLists,
  useChurch
} from '../data/use_church';
import {useLocalStorage} from '../data/use_local_storage';
import {LocalStorageKey} from '../data/client_local_storage';
import {
  Church,
  OrganizationType,
  SongList,
  SongListEntry,
  SongListType
} from '../common/model';
import {dateFromString} from '../common/date_string';
import {useHousehold} from '../data/use_household';
import {ensureUnreachable} from '../common/util';
import {Pages} from '../common/pages';
import {getUrlForPage} from '../util/path';
import {shareWithMusicLeader} from './onboarding/common';
import {DemoChurch, synchronizeDemoSongList} from '../data/demo_song_list';
import {stringFromChurch} from '../common/server_api';
import {useNavigate} from 'react-router-dom';

const todayTitle = 'Today';
const thisSundayTitle = 'This Sunday';
const lastSundayTitle = 'Last Sunday';
const nextSundayTitle = 'Next Sunday';
const thisWeekHymnalSlug = 'THIS-WEEK';

function getHymnalsFromSongLists(songsForWeek?: SongList[], church?: Church): HymnalWithHymns[] {
  if (songsForWeek === undefined) {
    return [];
  }
  const hymnals: HymnalWithHymns[] = songsForWeek
    .filter(songList => songList.type !== SongListType.StaticList || songsForWeek.length === 1)
    .map((songList) => ({
      id: 0,
      name: getSongListName(songList, songsForWeek.length),
      hymns: songList.songs.map(song => getHymnFromSongListEntry(song)), // b/c title is optional
      slug: thisWeekHymnalSlug,
    }));
  if (
    !songsForWeek.map(({date}) => date).includes(thisSundayDate()) &&
    church?.type === OrganizationType.Church &&
    !specialCaseSchools.includes(church?.name) &&
    // hide empty "This Sunday" for single static song list as well as demo church
    (songsForWeek.length > 1 || hymnals.length === 0 || hymnals[0].name !== '') &&
    stringFromChurch(church) !== DemoChurch
  ) {
    // keep hymn of the month first
    const containsHymnOfTheMonth = songsForWeek.length > 0 && songsForWeek[0].type === SongListType.HymnOfTheMonth;
    const start = containsHymnOfTheMonth ? 1 : 0;
    hymnals.splice(start, 0, {
      id: 0,
      name: getSundayTitle(thisSundayDate()),
      hymns: [],
      message: <div>Your music leader has not yet added any songs for this week.</div>,
      slug: thisWeekHymnalSlug,
    })
  }
  return hymnals;
}

function getSongListName(songList: SongList, songListCount: number) {
  const {name, type, date: dateStr, time, songs} = songList;
  switch (type) {
    case SongListType.HymnOfTheMonth:
      return getHymnOfTheMonthTitle(songs);
    case SongListType.WorshipService:
      return getSundayTitle(dateStr);
    case SongListType.Event:
      const date = dateFromString(dateStr);
      const datePart = date ? ` - ${getDateAsShortString(date)}` : '';
      const shortTime = getTimeAsShortString(time);
      const timePart = shortTime ? ` @ ${shortTime}` : '';
      return `${name}${datePart}${timePart}`;
    case SongListType.StaticList:
      // If there is only one static song list, it gets an empty title (flat list of songs)
      return songListCount === 1 ? '' : 'Songs';
    default:
      ensureUnreachable(type);
  }
}

function getHymnOfTheMonthTitle(songs: SongListEntry[]) {
  const isPsalm = songs.length > 0 && (songs[0].psalm || songs[0].title?.toLowerCase().includes('psalm'));
  const prefix = isPsalm ? 'Psalm' : 'Hymn';
  return `${prefix} of the Month`;
}

function getSundayTitle(date: string) {
  const today = getDateAsLongString(new Date());
  switch (date) {
    case today:
      return todayTitle;
    case thisSundayDate():
      return thisSundayTitle;
    case lastSundayDate():
      return lastSundayTitle;
    case nextSundayDate():
      return nextSundayTitle;
    default:
      return date;
  }
}

function nextSundayDate() {
  const today = new Date();
  if (today.getDay() !== 0) {
    return 'no match';
  }
  const result = today;
  result.setDate(today.getDate() + 7);
  return getDateAsLongString(result);
}

function thisSundayDate() {
  const today = new Date();
  const result = today;
  result.setDate(today.getDate() + (today.getDay() > 0 ? 7 - today.getDay() : 0));
  return getDateAsLongString(result);
}

function lastSundayDate() {
  const today = new Date();
  const result = today;
  result.setDate(today.getDate() + (today.getDay() > 0 ? 7 - today.getDay() : 0) - 7);
  return getDateAsLongString(result);
}

function getDateAsLongString(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function getDateAsShortString(date: Date) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return `${months[date.getMonth()]} ${date.getDate()}`;
}

function getTimeAsShortString(time?: string): string | undefined {
  if (!time) {
    return;
  }
  const match = time.match(/T(?<hour>\d\d):(?<minute>\d\d):(?<second>\d\d)/);
  if (!match || !match.groups) {
    return;
  }
  const {hour: hourString, minute} = match.groups;
  const militaryHour = Number(hourString);
  const hour = militaryHour === 0 ? 12 : (militaryHour > 12 ? militaryHour - 12 : militaryHour);
  const ampm = militaryHour < 12 || militaryHour === 24 ? 'am' : 'pm';
  return `${hour}:${minute}${ampm}`;
}

export interface Props {
  isDemo?: boolean;
}

export const ThisWeekPage = ({isDemo}: Props) => {
  const {church} = useChurch();
  const {household} = useHousehold();
  const [songsForWeek] = useLocalStorage(LocalStorageKey.WeeklySongList);
  const [demoSongs] = useLocalStorage(LocalStorageKey.DemoSongList);
  const [hymnals, setHymnals] = useState(() =>
    getHymnalsFromSongLists(songsForWeek, church)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (isDemo) {
      void synchronizeDemoSongList();
    } else {
      void synchronizeWeeklySongLists();
    }
  }, [isDemo]);

  useEffect(() => {
    setHymnals(getHymnalsFromSongLists(isDemo ? demoSongs : songsForWeek, isDemo ? undefined : church));
  }, [isDemo, demoSongs, songsForWeek, church]);

  const onShare = useCallback(async () => {
    await shareWithMusicLeader();
  }, []);

  const askYourMusicLeader =
    <button className="inlineLink" onClick={onShare}>
      ask your music leader
    </button>;

  let message: JSX.Element | undefined;
  if (isDemo) {
    message = undefined;
  } else if (!household) {
    // this code path should be impossible (RootPage should have navigated to onboarding demo, passing isDemo=true)
    // displaying URL (window.location.href) as a debugging tool because some customers are still seeing it... :(
    message =
      <div>
        To view songs for your church, {askYourMusicLeader} to add your church to the app or <span
        className="inlineLink" onClick={() => navigate(getUrlForPage(Pages.Settings))}>sign in</span> if your church
        already participates.<br/>
        <br/>
        <p>{window.location.href}</p>
      </div>;
  } else if (!church) {
    message = <div>To use this week, select a church in household <span className="inlineLink" onClick={() => navigate(getUrlForPage(Pages.Settings))}>settings</span> or  {askYourMusicLeader} to add your church to the app. </div>;
  } else if (songsForWeek === undefined) {
    message = <div>Downloading songs for this week...</div>;
  } else if (hymnals.length === 0) {
    message = <div>Your music director has not yet added songs for this Sunday.</div>;
  }
  return (
    <HymnsList
      title={church?.name ?? 'Your Church'}
      weeklySongGroups={hymnals}
      expandForThisWeek={true}
      message={message}
      isDemo={isDemo}
    />
  );
};
