import autoBind from 'auto-bind';
import {MidiFilePlayer} from './midi_file_player';
import {generateFileName, generatePathFor} from './util/path';
import {
  addListener,
  defaultVerseCount,
  localStorageGet,
  LocalStorageKey,
  SongIntroduction
} from './data/client_local_storage';
import {HymnIssue, SongFeature} from "./common/model";
import {MidiFilePlayerWebAudio} from "./midi_file_player_web_audio";
import {ensureExists} from "./common/util";
import {VocalsPlayer} from "./vocals_player";
import {hymnalsDir} from "./common/paths";
import {VocalsPlayerSimple} from "./vocals_player_simple";
import {Stopwatch} from './util/stopwatch';

// TODO(hewitt): Move this monstrosity into model.ts?!?
export interface Hymn {
  basePath?: string;
  hymnal: string;
  number: number;
  title: string;
  psalm?: string;
  issue?: HymnIssue;
  verseCount?: number;
  author?: string;
  composer?: string;
  harmony?: string;
  copyright?: string;
  key?: string;
  features?: SongFeature[];
  vocals?: string;
  vocalPartCount?: string;
  extensions?: string[];
  beatsPerMeasure?: number[];
  firstMeasureNumber?: number;
  measurePercentages?: number[];
}

type AnyPlayer = MidiFilePlayer | MidiFilePlayerWebAudio | VocalsPlayer | VocalsPlayerSimple;

export interface SequencerProps {
  onHymnChange: (hymn: Hymn) => void;
  onReadyToPlay: () => void;
  onNetworkError: (response?: Response) => void;
  setPlaying: (playing: boolean) => void;
  onVerseChange: (verse: string) => void;
  stopwatch: Stopwatch;
  setMidiFileTempo: (tempo: number) => void;
  updateLocationSlider: () => void;
}

export class Sequencer {
  private songIntroduction?: SongIntroduction;
  songIntroductionPercent = 0;
  songIntroPlayed: boolean = false;
  private _verseIndex = 0; // todo: state change event to UI?
  private _songIndex = 0; // use setSongIndex to update!
  private _modulationHalfSteps = 0;
  private _playing = false;
  private _stopwatch?: Stopwatch;
  private _stopwatchSubscription: number | undefined;
  private readonly _hymns: Hymn[];
  private _midiFilePlayer?: AnyPlayer;
  private _onHymnChange?: (hymn: Hymn) => void;
  private _onReadyToPlay?: () => void;
  private _onNetworkError?: (response?: Response) => void;
  private _setPlaying?: (playing: boolean) => void;
  private  _onVerseChange?: (verse: string) => void;
  private get _hymn() { return this._hymns[this._songIndex]; }
  private _vocalsEnabled: boolean
  private setMidiFileTempo?: (tempo: number) => void;
  private currentTempo: number | undefined;
  private updateLocationSlider?: () => void;

  constructor(hymns: Hymn[]) {
    autoBind(this);
    this.songIntroduction = localStorageGet(LocalStorageKey.SongIntroduction);
    this._hymns = hymns;
    this._vocalsEnabled = false;
  }

  setProps({
    onHymnChange,
    onNetworkError,
    onReadyToPlay,
    onVerseChange,
    setPlaying,
    stopwatch,
    setMidiFileTempo,
    updateLocationSlider,
  }: SequencerProps) {
    this._onHymnChange = onHymnChange;
    this._setPlaying = setPlaying;
    this._onReadyToPlay = onReadyToPlay;
    this._onNetworkError = onNetworkError;
    this._onVerseChange = onVerseChange;
    this.setMidiFileTempo = setMidiFileTempo;
    this._stopwatch = stopwatch;
    this.updateLocationSlider = updateLocationSlider;
    if (!this._midiFilePlayer) {
      this._midiFilePlayer = this._createMidiFilePlayer();
      this._refreshKeyModulation();
      addListener(LocalStorageKey.KeyModulation, this._refreshKeyModulation);
    }
  }

  play() {
    if (this.songIntroduction && !this.songIntroPlayed && this.songIntroductionPercent) {
      this._midiFilePlayer?.setLocationPercentage(this.songIntroductionPercent);
    }
    this._midiFilePlayer?.play();

    this._playing = true;
    this._setPlaying?.(this._playing);
    if (this._stopwatchSubscription) {
      console.log('Warning: Stopwatch should not be running prior to playing song (iOS browser bug?)');
      this._stopwatch?.unsubscribe(this._stopwatchSubscription);
    }
    this._stopwatchSubscription = this._stopwatch?.subscribe();
  }

  get isPlaying() {
    return this._playing;
  }

  pause() {
    this._pause();
  }

  setVocalsEnabled(value: boolean) {
    this._vocalsEnabled = value;
    this._midiFilePlayer = this._createMidiFilePlayer();
    this._refreshKeyModulation();
  }

  private _pause() {
    this._midiFilePlayer?.pause();
    this._playing = false;
    this._setPlaying?.(this._playing);
    if (this._stopwatchSubscription) {
      this._stopwatch?.unsubscribe(this._stopwatchSubscription);
      this._stopwatchSubscription = undefined;
    }
  }

  private get _supportsLooping() {
    return this._midiFilePlayer instanceof MidiFilePlayer;
  }

  nextSong() {
    // just reuse the current midi file player if there is only one song
    this.songIntroPlayed = false;
    const repeat = localStorageGet(LocalStorageKey.Repeat);
    if (this._hymns.length === 1 && repeat && this._playing) {
      setTimeout(() => this.play(), 0)
      return;
    }
    const playing = this._playing;
    this._setSongIndex((this._songIndex + 1) % this._hymns.length);
    if (playing && (repeat || this._songIndex !== 0)) {
      setTimeout(this.play, 200);
    }
  }

  previousSong() {
    const playing = this._playing;
    const {length} = this._hymns;
    const repeat = localStorageGet(LocalStorageKey.Repeat);
    this._setSongIndex(((this._songIndex - 1) + length) % length);
    if (playing && (repeat || this._songIndex !== 0)) {
      setTimeout(this.play, 200);
    }
  }

  get initialTime() { return this._midiFilePlayer?.initialTime; }

  reset() {
    this.pause();
    this.songIntroPlayed = false;

    this.songIntroduction = localStorageGet(LocalStorageKey.SongIntroduction);
    if (this.songIntroduction) {
      this.changeVerse(0, true)
    } else {
      this.changeVerse(1, false)
    }

    this._midiFilePlayer?.restart()
  }

  setNewTime(value: number) {
    this._midiFilePlayer?.setNewTime(value);
  }

  onVolumeChange() {
    this._midiFilePlayer?.onVolumeChange();
  }

  getCurrentTempo(): number | undefined {
    return this.currentTempo;
  }

  setCurrentTempo(value: number) {
    if (this.currentTempo === value) {
      return;
    }
    // TODO(hewitt): also adjust song duration / location
    this.currentTempo = value;
    this._midiFilePlayer?.onTempoChange();
  }

  get duration(): number | undefined { // in seconds
    return this._midiFilePlayer?.duration;
  }

  getCurrentPercent(): number | undefined {
    return this._midiFilePlayer?.getCurrentPercent();
  }

  destroy() {
    this._pause()
    this._midiFilePlayer?.destroy();
  }

  private _setSongIndex(index: number) {
    this._pause();
    if (this._songIndex !== index) {
      this._playing = false;
      this._songIndex = index;
      this._verseIndex = 0;
      this._midiFilePlayer = this._createMidiFilePlayer();
      this._refreshKeyModulation();
    }
  }

  private _songReady() {
    this._onReadyToPlay?.();
    this._onVerseChange?.(this._verseIndex.toString())
    if (this._playing) {
      setTimeout(this.play, 200)
    }
  }

  private _handleNetworkError(response?: Response) {
    this._onNetworkError?.(response);
  }

  private _createMidiFilePlayer() {
    if (this._midiFilePlayer) {
      this._pause();
      this.destroy();
    }
    let midiFilePlayer: AnyPlayer;
    if (this._vocalsEnabled && this._hymn.vocals) {
      midiFilePlayer = new VocalsPlayer(
        this._hymn.hymnal,
        this._hymn.vocals,
        this._songReady,
        this._handleNetworkError,
        this._endOfVerse,
        this.updateLocationSlider,
      );
      // use VocalsPlayerSimple outside the iOS app
      // midiFilePlayer = new VocalsPlayerSimple(
      //   this._hymn.hymnal,
      //   songFilename,
      //   this._songReady,
      //   this._handleNetworkError,
      //   this._endOfVerse,
      // );
    } else {
      const songFilename = generateFileName(this._hymn);
      const basePath = this._hymn.basePath ?? `/${hymnalsDir}`;
      const path = generatePathFor(basePath, ensureExists(this._hymn.hymnal), songFilename + '.mid');
      midiFilePlayer = new MidiFilePlayer({
        midiFilePath: path,
        songReady: this._songReady,
        onNetworkError: this._handleNetworkError,
        endOfFile: this._endOfVerse,
        modulationHalfSteps: () => this._modulationHalfSteps,
        setMidiFileTempo: ensureExists(this.setMidiFileTempo),
        getCurrentTempo: ensureExists(this.getCurrentTempo),
        updateLocationSlider: this.updateLocationSlider,
      });
    }
    setTimeout(() => this._onHymnChange?.(this._hymn));
    return midiFilePlayer;
  }

  private _refreshKeyModulation() {
    this._modulationHalfSteps = 0;
    const transposedSongs = localStorageGet(LocalStorageKey.KeyModulation);
    for (const {hymnalName, songNumber, transposeValue} of transposedSongs) {
      if (this._hymn.hymnal === hymnalName && this._hymn.number === songNumber) {
        this._modulationHalfSteps = transposeValue * 2;
        this._midiFilePlayer?.onVolumeChange();
      }
    }
  }

  private _endOfVerse() {
    let verseCount = localStorageGet(LocalStorageKey.VerseCount)
      || this._hymn.verseCount
      || defaultVerseCount;

    if (this.songIntroduction) {
      verseCount = verseCount + 1
    }

    if (this.songIntroduction && !this.songIntroPlayed) {
      this.songIntroPlayed = true
    }
    this._verseIndex = (this._verseIndex + 1) % verseCount;
    this._onVerseChange?.(this._verseIndex.toString())

    if (this._supportsLooping) {
      // the audio element continues onto the next verse automatically, so just refresh the time slider
      this.updateLocationSlider?.();
    } else {
      this._midiFilePlayer?.restart();
    }

    if (this._verseIndex === 0) {
      this.nextSong()
    } else if (!this._supportsLooping) {
      this._midiFilePlayer?.play()
    }
  }

  changeVerse(verse: number, intro: boolean | undefined) {
    if(intro) {
      this._verseIndex = verse
    } else {
      this._verseIndex = verse - 1;
    }

    this.songIntroPlayed = !(this._verseIndex === 0 && this.songIntroduction);

    this._onVerseChange?.(this._verseIndex.toString())

    setTimeout(() => {
      this._midiFilePlayer?.restart()
      if(this._playing) {
        this.play()
      }
    })
  }
}
