import React, {useCallback, useEffect, useRef} from 'react';
import {Message, TextInput} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {OnboardingPages} from '../../common/pages';
import {useChurch} from '../../data/use_church';
import {LocalStorageKey} from '../../data/client_local_storage';
import {useLocalStorage} from '../../data/use_local_storage';
import {beginFileUpload} from './custom_music_upload';
import {useNavigate} from 'react-router-dom';

export const CustomMusicUploadPsalmNumber = () => {
  const [songName] = useLocalStorage(LocalStorageKey.MusicUploadSongName);
  const [psalmNumber, setPsalmNumber] = useLocalStorage(LocalStorageKey.MusicUploadPsalmNumber);
  const psalmNumberInput = useRef<HTMLInputElement>(null);
  const {church} = useChurch();
  const fileChooserInput = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    psalmNumberInput.current?.focus();
  }, [psalmNumberInput]);

  const onPsalmNumberChanged = useCallback(() => {
    const value = psalmNumberInput.current?.value;
    if (value === undefined) {
      setPsalmNumber(undefined);
    }
    const number = psalmNumberInput.current?.value?.match(/(?<number>\d+)/)?.groups?.number;
    if (!number) {
      setPsalmNumber(undefined);
    }
    setPsalmNumber(Number(number));
  }, [psalmNumberInput, setPsalmNumber]);

  const onUpload = useCallback(() => {
    fileChooserInput?.current?.click();
  }, []);

  const onFileChosen = useCallback(() => {
    const file = fileChooserInput?.current?.files?.[0];
    if (!file || !church || !songName) {
      return;
    }
    void beginFileUpload(navigate, file);
  }, [church, navigate, songName]);

  return (
    <Frame
      onboardingPage={OnboardingPages.CustomMusicUploadPsalmNumber}
      mainContent={(
        <>
          <input type="file" id="imgupload" style={{display: "none"}} ref={fileChooserInput} onChange={onFileChosen}/>
          <Message $widthInChars={24}>What is the Psalm number?</Message>
          <TextInput
            type='number'
            enterKeyHint='next'
            onChange={onPsalmNumberChanged}
            defaultValue={psalmNumber}
            ref={psalmNumberInput}
          />
        </>
      )}
      footerContent={(
        <>
          <Button onClick={onUpload} disabled={!psalmNumber}>UPLOAD</Button>
        </>
      )}
      onEnterKeypress={onUpload}
      containsInputElement={true}
    />
  );
};
