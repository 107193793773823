import {useCallback, useRef} from 'react';
import {ensureExists} from "../common/util";
import styled from 'styled-components';

interface Props {
  className?: string;
  defaultValue?: number;
  value?: number;
  id: string;
  max?: number;
  min?: number;
  onChange?: (value: number) => void;
  onPointerUp?: (value: number) => void;
  step?: number;
}

export function Slider({className, defaultValue, value, id, max, min, onChange, onPointerUp, step}: Props) {
  const onInput = useCallback(() => {
    const slider = ensureExists(document.getElementById(id));
    onChange?.(Number((slider as HTMLInputElement).value));
  }, [id, onChange]);

  const _onPointerUp = useCallback(() => {
    const slider = ensureExists(document.getElementById(id));
    onPointerUp?.(Number((slider as HTMLInputElement).value));
  }, [id, onPointerUp])

  const inputRef = useRef<HTMLInputElement>(null);

  if (value !== undefined && inputRef?.current) {
    inputRef.current.value = value.toString();
  }

  return (
    <SliderBase
      className={className}
      type='range'
      id={id}
      defaultValue={defaultValue}
      min={min}
      max={max}
      step={step}
      onInput={onInput}
      onPointerUp={_onPointerUp}
      ref={inputRef}
    />
  );
}

export const SliderBase = styled.input`
  /* Should be shared with LocationSlider, but does not work - keep these in sync! */
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: var(--color-slider);
    /*box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;*/
    /*border-radius: 1.3px;*/
    /*border: 0.2px solid #010101;*/
  }
`;
