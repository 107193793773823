import {ReactComponent as BackArrow} from '../assets/arrow-previous-left-icon.svg';
import React, {useEffect, useState} from 'react';
import {requestSongPageDialog, RequestSongPage} from './request_song_page';
import {ensureExists} from '../common/util';
import {Document, Page} from 'react-pdf';
import {Hymn} from '../sequencer';
import useWindowDimensions from '../util/useWindowDimensions';
import {encodeMusicURI, generateFileName} from '../util/path';
import {hymnalsPDFDir} from '../common/paths';
import {useUserAttributes} from '../data/use_user_attributes';
import styled from 'styled-components/macro';
import {HymnIssue} from '../common/model';
import {useLocalStorage} from '../data/use_local_storage';
import {LocalStorageKey} from '../data/client_local_storage';

interface Props {
  hymn: Hymn;
  onClose: () => void;
}

export function PdfViewerPage({hymn, onClose}: Props) {
  const [numPages, setNumPages] = useState<number | undefined>(undefined);
  const windowDimensions = useWindowDimensions();
  const pdfFile = generateFileName(hymn) + '.pdf';
  const basePath = hymn.basePath ?? `/${hymnalsPDFDir}`;
  const pdfPath = `${basePath}/${encodeMusicURI(ensureExists(hymn.hymnal))}/${encodeMusicURI(pdfFile)}`;
  const {isInternalUser} = useUserAttributes();
  const [requestSongDisplayedForPdf, setRequestSongDisplayedForPdf] =
    useLocalStorage(LocalStorageKey.RequestSongDisplayedForPDF);

  useEffect(() => {
    void (async() => {
      if (requestSongDisplayedForPdf[hymn.hymnal]?.[hymn.number]) {
        return;
      }
      if (hymn.extensions?.includes('mid') || !hymn.extensions?.includes('pdf')) {
        return;
      }
      setRequestSongDisplayedForPdf({
        ...requestSongDisplayedForPdf, [hymn.hymnal]: {
          ...requestSongDisplayedForPdf[hymn.hymnal], [hymn.number]: true
        }
      });
      await requestSongPageDialog({confirmation: {
        issue: HymnIssue.Missing,
        hymnalName: ensureExists(hymn.hymnal),
        songNumber: hymn.number,
      }});
    })();
  }, [requestSongDisplayedForPdf, setRequestSongDisplayedForPdf, hymn.extensions, hymn.hymnal, hymn.number])

  return (
    <PdfDoc key='pdf'>
      <BackButton onClick={onClose}><BackArrow/></BackButton>
      <div>
        <div style={{...(hymn.issue !== HymnIssue.Text && {display: 'none'})}}>
          {
            !isInternalUser
              ? <RequestSongPage
                  issue={HymnIssue.Text}
                  hymnalName={ensureExists(hymn.hymnal)}
                  songNumber={hymn.number}/>
              : null
          }
        </div>
        <div style={{...(hymn.issue === HymnIssue.Text && !isInternalUser && {display: 'none'})}}>
          <Document
            file={pdfPath}
            options={{workerSrc: "/pdf.worker.js"}}
            onLoadSuccess={({numPages}) => setNumPages(numPages)}
            onLoadError={console.error}
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map(page => <Page pageNumber={page} key={page} width={windowDimensions.width}/>)}
          </Document>
        </div>
      </div>
    </PdfDoc>
  );
}

const PdfDoc = styled.div`
  overflow-y: scroll;
  overflow-x: clip;
  padding-bottom: 30px;
  transform: translateY(-60px);
`

const BackButton = styled.div`
  position: sticky;
  top: 20px;
  width: 32px;
  z-index:1000;
  background-color: rgba(255, 255, 255, 0.8);
  margin-left: 8px;
  padding: 5px 10px;
  border-radius: 3px;
`
