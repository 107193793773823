import {HouseholdSchedule} from '../common/model';
import {addListener, localStorageGet, LocalStorageKey} from '../data/client_local_storage';
import {getUrlForPage} from './path';
import {Pages} from '../common/pages';

enum DayOfWeek {Sunday = 0, Monday = 1, Tuesday = 2, Wednesday = 3, Thursday = 4, Friday = 5, Saturday = 6}

const householdPushNotificationTitle = 'Sing Your Part';

const sound = 'arpeggio.wav';  // can be either undefined or a sound file in the app bundle (e.g. 'arpeggio.wav')

const householdPushNotificationBody: {[key in DayOfWeek]: string} = {
  [DayOfWeek.Sunday]: 'Happy Lord\'s day!',
  [DayOfWeek.Monday]: 'Check out the songs your church is singing this week!',
  [DayOfWeek.Tuesday]: 'Let\'s sing your favorite song from last Sunday',
  [DayOfWeek.Wednesday]: 'Halfway through the week; start getting ready for Sunday',
  [DayOfWeek.Thursday]: 'Three days away from church; make sure we\'re ready to sing',
  [DayOfWeek.Friday]: 'Finish the week strong; end it with singing!',
  [DayOfWeek.Saturday]: 'Are you ready to sing your part tomorrow?',
}

const householdPushNotificationTimes: {[key in HouseholdSchedule]: Pick<PushNotification, 'hour' | 'minute'>} = {
  [HouseholdSchedule.Morning]: {hour: 8, minute: 0},
  [HouseholdSchedule.Lunch]: {hour: 11, minute: 30},
  [HouseholdSchedule.Dinner]: {hour: 17, minute: 0},
  [HouseholdSchedule.Bedtime]: {hour: 19, minute: 30},
}

const householdPushNotificationIdentifierPrefix = 'household'

interface NativeInterface {
  arePushNotificationsAllowed(requestPermission: boolean): void;
  schedulePushNotification(notification: PushNotification): void;
  getPushNotifications(): void;
  cancelPushNotification(identifier: string): void;
}

export function deviceSupportsNotifications() {
  return Boolean(getNativeInterface()?.arePushNotificationsAllowed);
}

export enum PushNotificationAuthorization {
  Authorized = "authorized",
  Denied = "denied",
  NotDetermined = "notDetermined",
}

export async function getPushNotificationsAuthorization(
  {requestPermission}: {requestPermission: boolean}
): Promise<PushNotificationAuthorization> {
  const nativeInterface = getNativeInterface();
  if (!nativeInterface?.arePushNotificationsAllowed) {
    console.log(`ERROR: failed to locate arePushNotificationsAllowed on window.webkit?.messageHandlers`);
    return PushNotificationAuthorization.Denied;
  }

  // tricky b/c Apple does not allow a response from a message handler, so we receive it async on a callback
  let result = PushNotificationAuthorization.NotDetermined;
  let resolver: ((value: boolean | PromiseLike<boolean>) => void) | undefined;

  // @ts-ignore
  const {receivePushNotificationsAuthorization} = globalThis;
  // @ts-ignore
  globalThis.receivePushNotificationsAuthorization = (
    {authorization}: {authorization: PushNotificationAuthorization}
  ) => {
    result = authorization;
    resolver?.(true);
  }

  try {
    await new Promise<boolean>((res) => {
      resolver = res;
      // @ts-ignore
      nativeInterface.arePushNotificationsAllowed.postMessage({requestPermission});
    });
  } finally {
    // @ts-ignore
    globalThis.receivePushNotificationsAuthorization = receivePushNotificationsAuthorization;
  }
  return result;
}

function getNativeInterface() : NativeInterface | undefined {
  // @ts-ignore
  return window.webkit?.messageHandlers as NativeInterface | undefined;
}

interface PushNotification {
  identifier: string;
  title: string;
  body: string;
  hour: number;       // hour is in military time (e.g. 14 = 2pm)
  minute: number;
  recurring: boolean;
  weekday?: number;    // 1 = Sunday and 7 = Saturday
  sound?: string;      // name of sound file in iOS app bundle
}

async function setupAllHouseholdPushNotifications(): Promise<void> {
  await cancelAllHouseholdPushNotifications();
  const {schedule} = localStorageGet(LocalStorageKey.Household)?.surveyResponses ?? {};
  const nativeInterface = getNativeInterface();
  if (!schedule || !deviceSupportsNotifications()) {
    return;
  }
  for (const entry of schedule) {
    for (const notification of createPushNotificationsFromScheduleEntry(entry)) {
      // @ts-ignore
      nativeInterface.schedulePushNotification.postMessage(notification);
    }
  }
}

function createPushNotificationsFromScheduleEntry(entry: HouseholdSchedule): PushNotification[] {
  return Object.values(DayOfWeek)
    .filter(value => Number.isInteger(value))
    .map(dayOfWeek => createPushNotification(entry, dayOfWeek as DayOfWeek));
}

export function createPushNotification(
  entry: HouseholdSchedule, dayOfWeek: DayOfWeek
): PushNotification {
  const weekday = dayOfWeek + 1;
  // format: 'household-morning-2' (Monday morning identifier)
  const identifier = householdPushNotificationIdentifierPrefix + '-' + entry + '-' + weekday.toString();
  const title = householdPushNotificationTitle;
  const body = householdPushNotificationBody[dayOfWeek];
  const time = householdPushNotificationTimes[entry];
  return {identifier, title, body, ...time, recurring: true, weekday, sound: sound};
}

export async function refreshHouseholdPushNotifications() : Promise<void> {
  await cancelAllHouseholdPushNotifications();
  await setupAllHouseholdPushNotifications();
}

async function cancelAllHouseholdPushNotifications() : Promise<void> {
  for (const identifier of await getPushNotificationIdentifiers()) {
    if (identifier.startsWith(householdPushNotificationIdentifierPrefix)) {
      await cancelPushNotification(identifier);
    }
  }
}

async function getPushNotificationIdentifiers(): Promise<string[]> {
  const nativeInterface = getNativeInterface();
  if (!nativeInterface?.getPushNotifications) {
    console.log(`ERROR: Unable to locate getPushNotifications on native interface`);
    return [];
  }

  let result: string[] = [];
  let resolver: ((value: boolean | PromiseLike<boolean>) => void) | undefined;

  // @ts-ignore
  const {receivePushNotifications} = globalThis;
  // @ts-ignore
  globalThis.receivePushNotifications = (
    {identifiers}: {identifiers: string[]}
  ) => {
    result = identifiers;
    resolver?.(true);
  }

  try {
    await new Promise<boolean>((res) => {
      resolver = res;
      // @ts-ignore
      nativeInterface.getPushNotifications.postMessage({});
    });
  } finally {
    // @ts-ignore
    globalThis.receivePushNotifications = receivePushNotifications;
  }
  return result;
}

async function cancelPushNotification(identifier: string): Promise<void> {
  const nativeInterface = getNativeInterface();
  if (!nativeInterface?.cancelPushNotification) {
    console.log(`ERROR: Unable to locate cancelPushNotification on native interface`);
    return;
  }
  // @ts-ignore
  nativeInterface.cancelPushNotification.postMessage({identifier});
}

export function registerPushNotificationHandlers(navigate: (to: string) => Promise<void>) {
  registerForPushNotificationClicks(navigate);
  registerForHouseholdScheduleUpdates();
  registerForOnAppDidBecomeActive();
}

function registerForPushNotificationClicks(navigate: (to: string) => Promise<void>) {
  if (!deviceSupportsNotifications()) {
    return;
  }
  // @ts-ignore
  globalThis.receiveOnPushNotificationClicked = (identifier: string) => {
    console.log(`Clicked on notification ${identifier}`);
    void navigate(getUrlForPage(Pages.ThisWeek));
  }
}

function registerForHouseholdScheduleUpdates() {
  if (!deviceSupportsNotifications()) {
    return;
  }
  let previousSchedule = localStorageGet(LocalStorageKey.Household)?.surveyResponses?.schedule;
  addListener(LocalStorageKey.Household, async () => {
    const schedule = localStorageGet(LocalStorageKey.Household)?.surveyResponses?.schedule;
    if (JSON.stringify(schedule) !== JSON.stringify(previousSchedule)) {
      console.log(`Household schedule update detected - refreshing push notifications`);
      await refreshHouseholdPushNotifications();
      previousSchedule = schedule;
    }
  });
}

// whenever app comes to the foreground, make sure we have permission to perform push notifications
function registerForOnAppDidBecomeActive() {
  if (!deviceSupportsNotifications()) {
    return;
  }
  // @ts-ignore
  globalThis.receiveOnAppDidBecomeActive = () => {
    const household = localStorageGet(LocalStorageKey.Household);
    if (household && household.surveyResponses) {
      void getPushNotificationsAuthorization({requestPermission: true});
    }
  }
}
