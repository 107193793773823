import React, {useCallback} from 'react';
import {Frame} from './frame';
import {Message, navigateToOnboardingPage} from './common';
import {Button} from './button';
import {OnboardingPages} from '../../common/pages';
import {useNavigate} from 'react-router-dom';

export const Awesome = () => {
  const navigate = useNavigate();
  const onContinue = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.SurveyHouseholdSchedule);
  }, [navigate]);
  return (
    <Frame
      onboardingPage={OnboardingPages.Awesome}
      mainContent={(
        <Message $widthInChars={16}>Awesome!</Message>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};
