import AudioBufferLite from "./audio_buffer_lite";
import {MidiData, MidiNoteOffEvent, MidiNoteOnEvent} from 'midi-file';
import {Voice, VoiceValues} from "../data/client_local_storage";
import {sleep} from "../common/sleep";
import {ensure, ensureExists} from "../common/util";

const midiChannelCount = 4;
const soundFontPath = 'sound-font.bin';
const loadCallbacks: (() => void)[] = [];
let loadInitiated = false;
let mp3Buffers: {[noteName: string]: AudioBuffer} | undefined;
let midiNoteNames: string[] = [];
const MaxMidiNoteVelocity = 127;

export function loadInstrument(callback: () => void): void {
  if (mp3Buffers) {
    callback();
  } else {
    loadCallbacks.push(callback);
    loadSoundfont();
  }
}

export function createAudioContext(): AudioContext {
  return new (window.AudioContext || (window as any).webkitAudioContext)();
}

export function generateMidiNoteNames() {
  // note names look like 'A0', 'Bb0', 'B0', 'C1', 'Db1', etc.
  // they wrap when going from B to C
  // see https://www.inspiredacoustics.com/en/MIDI_note_numbers_and_center_frequencies
  const midiLetters = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'];
  const startingNumberOffset = 21;
  const startingLetterIndex = 9;
  const pianoKeyCount = 88;
  for (let pianoKey = 0; pianoKey < pianoKeyCount; pianoKey += 1) {
    const noteNumber = startingNumberOffset + pianoKey;
    const midiOctave = Math.floor((pianoKey + startingLetterIndex) / midiLetters.length);
    const letter = midiLetters[(startingLetterIndex + pianoKey) % midiLetters.length];
    midiNoteNames[noteNumber] = letter + midiOctave.toString();
  }
}

export interface ActiveNote {
  gainNode: GainNode,
  gainValue: number,
}

/**
 * must call loadInstrument first and wait for the callback before calling playNote
 */
export function playNote(audioContext: AudioContext, noteName: string, gain: number, when: number): ActiveNote {
  ensure(noteName in ensureExists(mp3Buffers), `Note "${noteName}" not found in mp3_buffers`);
  const buffer = ensureExists(mp3Buffers)[noteName];
  const source = audioContext.createBufferSource();
  const gainNode = audioContext.createGain();
  source.buffer = buffer;
  source.connect(gainNode);
  gainNode.connect(audioContext.destination);
  gainNode.gain.setValueAtTime(gain, when);
  source.start(when);
  return {gainNode, gainValue: gain};
}

export function stopNote({gainNode, gainValue}: ActiveNote, when: number): any {
  if (gainValue > 0) {
    // quick fade better than sudden stop (causes pop if stopping sign wave at non-zero location)
    // fade begins at previous event (which explains the duplicate setValueAtTime)
    gainNode.gain.setValueAtTime(gainValue, when);
    gainNode.gain.exponentialRampToValueAtTime(0.0001, when + 1 / 8);
  }
}

export enum MP3GeneratorResult {
  Success = 'success',
  Waiting = 'waiting',
  Failure = 'failure',
}

type MidiNoteEvent = MidiNoteOnEvent | MidiNoteOffEvent;

interface MP3Note {
  buffer: AudioBuffer;
  startSec: number;
  durationSec: number;
  volumeMultiplier: number;
}

export interface MP3Inputs {
  tempo: number;
  modulationHalfSteps: number;
  voiceValues: VoiceValues;
}

//
// PERFORMANCE NOTE
//
// Generating the audio blob file causes the UI thread to block
// Surprisingly, the blocking has very little to do with the code below
// It appears to be garbage collection, triggered by returning the mp3Url
// If we still do all the same work but don't return the URL, everything is much smoother,
// even if the caller doesn't use the returned URL - just returning it causes the blockage on iOS.
// Difficult to profile locally b/c the desktop is so much faster & has a lot of memory
// The local profiler points to export as causing the blockage
export async function generateWavBlobForMIDI(
  midi: MidiData,
  {
    tempo,
    modulationHalfSteps,
    voiceValues,
  }: MP3Inputs
): Promise<{
  audioBlob?: Blob;
  result: MP3GeneratorResult;
}> {
  // todo(hewitt): how do we not leak previous audio buffers when creating new ones?

  if (!mp3Buffers || tempo === 0) {
    return {result: MP3GeneratorResult.Waiting};
  }

  const {ticksPerBeat} = midi.header;
  if (!ticksPerBeat) {
    console.log(`MidiError: Missing ticksPerBeat in midi header`);
    return {result: MP3GeneratorResult.Failure}
  }
  try {
    const notes: MP3Note[] = [];
    let totalDurationSec = 0;

    for (let trackIndex = 0; trackIndex < midi.header.numTracks; trackIndex++) {
      const noteEvents = midi.tracks[trackIndex]
        .filter(event => event.type === 'noteOn' || event.type === 'noteOff') as MidiNoteEvent[];
      if (noteEvents.length === 0) {
        continue;
      }
      for (let channelIndex = 0; channelIndex < midiChannelCount; channelIndex++) {
        const {notes: channelNotes, durationSec} = getMP3NotesFromMidiEvents(
          noteEvents, channelIndex, ticksPerBeat, tempo, modulationHalfSteps, voiceValues,
        );
        notes.push(...channelNotes);
        totalDurationSec = Math.max(totalDurationSec, durationSec);
      }
    }

    const {numberOfChannels, sampleRate} = mp3Buffers['C4'];
    let buffer = new AudioBufferLite(numberOfChannels, sampleRate, totalDurationSec);
    for (const [index, note] of notes.entries()) {
      const {buffer: noteBuffer, startSec, durationSec, volumeMultiplier} = note;
      buffer.recordNote(noteBuffer, startSec, durationSec, volumeMultiplier);
      if (index % 20 === 0) { // yield to UI events
        await sleep(0);
      }
    }
    await buffer.normalize({normalizationTarget: Math.max(...Object.values(voiceValues))});
    return {audioBlob: buffer.exportAsWavBlob(), result: MP3GeneratorResult.Success};
  } catch (e: any) {
    console.log(e.message);
    return {result: MP3GeneratorResult.Failure}
  }
}

export function loadSoundfont() {
  if (!loadInitiated) {
    loadInitiated = true;
    fetch(soundFontPath)
      .then((response) => {
        if (!response.ok) {
          loadInitiated = false;
          throw new Error(`failed to load sound font ${response.status}, ${response.statusText}`)
        }
        return response.arrayBuffer()})
      .then((soundFont: ArrayBuffer) => {
        const audioContext = createAudioContext();
        const soundFontLength = soundFont.byteLength;
        const dataView = new DataView(soundFont);
        const bufferCount = dataView.getUint32(0);
        const noteNameSize = 4;
        const uint32Size = 4;
        let currentOffset = uint32Size;
        const promises = [];
        for (let buffer_index = 0; buffer_index < bufferCount; buffer_index++) {
          const noteName = new TextDecoder().decode(new DataView(soundFont, currentOffset, noteNameSize)).trim()
          currentOffset += noteNameSize;
          const bufferLength = dataView.getUint32(currentOffset);
          currentOffset += uint32Size;
          const buffer = soundFont.slice(currentOffset, currentOffset + bufferLength);
          promises.push(audioContext.decodeAudioData(buffer).then((buffer) => {
            return {noteName, buffer};
          }));
          currentOffset += bufferLength;
          ensure(currentOffset <= soundFont.byteLength,
            `Expected currentOffset (${currentOffset}) <= soundFontLength (${soundFontLength})`);
        }
        Promise.all(promises).then((results) => {
          mp3Buffers = {};
          for (const {noteName, buffer} of results) {
            mp3Buffers[noteName] = buffer;
          }
          for (const callback of loadCallbacks) {
            callback();
          }
          loadCallbacks.length = 0;
        });
      })
  }
}

function getMP3NotesFromMidiEvents(
  noteEvents: MidiNoteEvent[],
  channel: number,
  ticksPerBeat: number,
  tempo: number,
  modulationHalfSteps: number,
  voiceValues: VoiceValues,
): {
  notes: MP3Note[];
  durationSec: number;
} {
  const notes: MP3Note[] = [];
  let currentTimeSec = 0;
  let voice: Voice;
  switch (channel) {
    default:
    case 0: voice = Voice.Soprano; break;
    case 1: voice = Voice.Alto; break;
    case 2: voice = Voice.Tenor; break;
    case 3: voice = Voice.Bass; break;
  }
  const voiceValue = voiceValues[voice];


  for (const noteEvent of noteEvents) {
    const {noteNumber, deltaTime, channel: noteChannel, velocity} = noteEvent;
    if (channel >= midiChannelCount) {
      throw new Error(`Note channel ${channel} exceeds channel count ${midiChannelCount}`);
    }
    currentTimeSec += ((deltaTime / ticksPerBeat) / tempo) * 60;
    if (noteChannel !== channel) {
      continue;
    }
    const noteName = midiNoteNames[noteNumber + modulationHalfSteps];
    if (noteEvent.type === 'noteOn') {
      const noteVelocity = velocity / MaxMidiNoteVelocity;
      const note = {
        buffer: ensureExists(mp3Buffers)[noteName],
        startSec: currentTimeSec,
        durationSec: 0,
        volumeMultiplier: noteVelocity * voiceValue
      };
      notes.push(note);
    } else {
      const firstEventIsNoteOff = notes.length === 0;
      if (firstEventIsNoteOff) {
        continue;
      }
      const redundantNoteOff = notes[notes.length - 1].durationSec > 0;
      if (redundantNoteOff) {
        continue;
      }
      const note = notes[notes.length - 1];
      note.durationSec = currentTimeSec - note.startSec;
    }
  }
  const durationSec = notes.reduce((accum, note) => accum + note.durationSec, 0);
  return {notes, durationSec};
}
