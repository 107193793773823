import styled from 'styled-components/macro';
import {Spinner} from '../util/spinner';

export const LoadingPage = () => (
  <LoadingPageWrapper>
    <Message>Loading...</Message>
    <Spinner />
  </LoadingPageWrapper>);

const LoadingPageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  font-size: 2em;
  color: var(--color-text);
  padding-bottom: 35px;
`;
