import {ChurchChoice} from './onboarding/church_choice';

export interface Props {
  visible: boolean;
  onSettingChanged(): void;
  onBack(): void;
}

export const ChooseChurchPage = ({visible, onSettingChanged, onBack}: Props) => !visible ? null : (
  <ChurchChoice onSettingChanged={onSettingChanged} onBack={onBack}/>
);
